/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  Space,
  message,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Slider,
  Tabs,
  AutoComplete,
  Input,
  Tag,
  Popconfirm,
  InputNumber,
  Tooltip,
  Divider,
} from 'antd';
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { BarsOutlined, MenuUnfoldOutlined, RocketOutlined, UserSwitchOutlined } from '@ant-design/icons';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/lib/table';
import { cloneDeep, debounce, isNil, remove } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import APIList from '../../http/ApiList';
import ProjectSetupTable, { ProjectSetupTableItem } from '../../components/tables/project-setup.table';
import { localeMapping } from '../../consts/locale.mapping';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import { RecordItem } from '../../contexts/provider/aipcmcty.provider.context';
import TurnoverChart from '../../components/charts/turnover.chart';
import GrossProfitCompositionChart from '../../components/charts/gross-profit-composition.chart';
import StrategyMatrixChart from '../../components/charts/strategy-matrix.chart';
import OrderAmountChart from '../../components/charts/order-amount.chart';
import OrderGrossProfit from '../../components/charts/order-gross-profit.chart';
import GrossProfitMargin from '../../components/charts/gross-profit-margin.chart';
import WonPercentChart from '../../components/charts/won-percent.chart';
import TimeValueChart from '../../components/charts/time-value.chart';
import CasesPercentageChart from '../../components/charts/cases-percentage.chart';
import GrossProfitPerMhChart from '../../components/charts/gross-profit-per-mh.chart';
import HumanResourcesTurnoverChart from '../../components/charts/human-resources-turnover.chart';
import SupplyDemandChart from '../../components/charts/supply-demand.chart';
import ProjectGrossProfitChart from '../../components/charts/project-gross-profit.chart';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import LayoutSwitcher from '../../components/common/layout/layout-switcher';
import MemoContainer from '../../components/widget/memo-container';
import ProjectSetupDetail from '../../components/project-setup-detail';
import ChartGroup from '@meteor/frontend-core/dist/chart/chart-card/chart-group';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ChartTableLayout } from '@meteor/frontend-core';

const timeout = 360000;

export const tableChangeMap = {
  orderAmount: (item) => {
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
  },
  grossMargin: (item) => {
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
  },
  projectStartDate: (item, diffDay) => {
    // item.duration = moment(item.projectEndDate).diff(moment(item.projectStartDate), 'months');
    item.projectStartDate = moment(item.projectStartDate).format('YYYY-MM-DD');
    item.projectEndDate = moment(item.projectEndDate).add(diffDay, 'days').format('YYYY-MM-DD');
    item.orderScheduledDate = moment(item.orderScheduledDate).add(diffDay, 'days').format('YYYY-MM-DD');
  },
  // projectEndDate: item => {
  //   item.duration = moment(item.projectEndDate).diff(moment(item.projectStartDate), 'months');
  // },
  duration: (item) => {
    if (item.duration <= 0) {
      item.duration = 1;
    }
    item.projectEndDate = moment(item.projectStartDate).add(item.duration, 'months').format('YYYY-MM-DD');
  },
  phase: (item, opts = []) => {
    const filterStr = item.certainty + item.phase;
    item.won = Number(opts.find((w) => w.label === filterStr).value);
  },
};

const ProjectSetup: React.FC = () => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('chart-table');
  const { selectorTop } = useWindowSize({
    selector: '.chart-group-container',
    viewMode,
  });

  // about drawer
  const { fiscalQuarter, periodSwitch, setMenuCollapsed, color, setScrollCount, locale, sizeMode, menuCollapsed, scrollCount } =
    useContext(AppContext);

  const { snapshot, snapshotVersion, tableCanOperation, setOrgKpiInfos, user } = useContext(AipcmctyContext);

  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const [memoCollapsed, setMemoCollapsed] = useState(true);
  const [memoInfo, setMemoInfo] = useState({
    projectId: null,
    projectName: '',
  });
  const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
    maxWidth: 340,
    minWidth: 0,
  };
  // about drawer form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const initialKpiOrder = [
    4, 5, 6, 2.1, 15, 31, 32, 33, 34, 23, 24, 27, 28, 7, 9, 10, 11, 12, 16, 17, 18, 22, 25, 29, 30, 21, 26, 1.1, 3.1, 1.2, 2.2, 3.2,
  ];
  const initialColumnFilter = {
    update: [],
    query: [],
    hidden: ['isExisted', 'customerRelationship', 'scope', 'dxDiscountRate', 'dxFullPotential'],
  };

  // actions about use case selected
  const actionOrder = [
    {
      strate: {
        budgetCategoryCustom: ['Budget', 'IF', 'Others'],
      },
      columnFilter: {
        update: ['priorityDefault', 'budgetCategoryCustom', 'won'],
        query: ['orderAmount', 'grossMargin', 'projectStartDate', 'isExisted', 'customerRelationship'],
        hidden: ['scope', 'grossProfitPerMh', 'dxFullPotential', 'dxDiscountRate'],
      },
      kpiOrder: [4, 5, 15, 23, 24, 27, 28, 7, 9, 10, 11, 12, 16, 17, 18, 22, 25, 29, 30, 31, 32, 33, 21, 26, 1.1, 2.1, 3.1, 1.2, 2.2, 3.2],
    },
    {
      strate: {
        budgetCategoryCustom: ['Budget'],
      },
      columnFilter: {
        update: ['grossMargin', 'demandMh', 'won', 'dxDiscountRate'],
        query: ['phase', 'grossProfitPerMh', 'scope', 'dxFullPotential'],
        hidden: ['isExisted', 'customerRelationship'],
      },
      kpiOrder: [15, 16, 27, 29, 31, 32, 33, 5, 23, 24, 28, 7, 9, 10, 11, 12, 17, 18, 22, 25, 30, 4, 21, 26, 1.1, 2.1, 3.1, 1.2, 2.2, 3.2],
    },
    {
      strate: {
        budgetCategoryCustom: ['Budget'],
      },
      columnFilter: {
        update: ['priorityDefault', 'budgetCategoryCustom', 'grossMargin', 'grossProfitPerMh', 'demandMh', 'won', 'dxDiscountRate'],
        query: ['scope'],
        hidden: ['isExisted', 'customerRelationship', 'dxFullPotential'],
      },
      kpiOrder: [31, 32, 33, 16, 24, 9, 10, 27, 29, 5, 23, 7, 11, 12, 16, 17, 18, 22, 25, 29, 30, 4, 21, 26, 1.1, 2.1, 3.1, 1.2, 2.2, 3.2],
    },
  ];

  // initialize the table data list and options
  const initializeTableData = async () => {
    setExpandedRows([]);
    const [data, options, creators] = await Promise.all([
      APIList.getProjectCase().get({
        snapshot,
        snapshotVersion,
        ...searchData,
      }) as Promise<ProjectSetupTableItem[]>,
      APIList.getCmcOptions().get({
        category: 'all',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
      APIList.getCmcOptions().get({
        category: 'memoCreators',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
    ]);
    data.forEach((d) => {
      d.isChildren = false;
      if (d.subCount > 0) {
        d.children = [];
      }
    });

    setTableDataNeedFilter(data);

    // 日本関与有無
    const japanInvolved = [
      {
        key: 1,
        value: 1,
        label: t('aipcmcty.page.have'),
      },
      {
        key: 0,
        value: 0,
        label: t('aipcmcty.page.no'),
      },
    ];

    // 会計対象
    const consolidated = [
      {
        key: t('aipcmcty.page.consolidated'),
        value: 1,
        label: t('aipcmcty.page.consolidated'),
      },
      {
        key: t('aipcmcty.page.single'),
        value: 0,
        label: t('aipcmcty.page.single'),
      },
    ];

    // memo tags
    const memoTag = [
      {
        key: '課題',
        value: '課題',
        label: '課題',
      },
      {
        key: 'TODO',
        value: 'TODO',
        label: 'TODO',
      },
      {
        key: '共有',
        value: '共有',
        label: '共有',
      },
    ];

    // memo creators
    const memoCreators = creators.map((creator) => ({
      key: creator.userId,
      value: creator.userId,
      label: creator.userName,
    }));

    const initialFiltersData = { ...filtersData, tgc: ['Toyo-J'] };
    const sliderProps = data.reduce(
      (prev, cur, index) => {
        prev.priorityDefault.push(cur.priorityDefault ?? index + 1);
        prev.grossMargin.push(cur.grossMargin * 100);
        prev.won.push(cur.won * 100);
        return prev;
      },
      {
        priorityDefault: [],
        grossMargin: [],
        won: [],
      }
    );
    const sliderOpts = Object.keys(sliderProps).reduce((prev, k) => {
      let min = 0;
      let max = 100;
      if (k === 'priorityDefault') {
        const range = sliderProps[k].length > 0 ? sliderProps[k] : [1, 100];
        min = Math.round(Math.min(...range));
        max = Math.round(Math.max(...range));
      }
      const stepLen = max.toFixed(0).length - 2;
      const step = ['won', 'grossMargin'].includes(k) ? 1 : Number(1 + Array.from({ length: stepLen }, () => 0).join(''));
      const markMin = ['won', 'grossMargin'].includes(k) ? `${min}%` : min;
      const markMax = ['won', 'grossMargin'].includes(k) ? `${max}%` : max;
      initialFiltersData[k] = [min, max];
      prev[`${k}SliderOpts`] = {
        min,
        max,
        range: true,
        step,
        marks: {
          [min]: markMin,
          [max]: markMax,
        },
      };
      return prev;
    }, {});
    const formKeysNeedDelete = ['tgc', 'accountingYear', 'useCase'];
    formInitialValue.current = formKeysNeedDelete.reduce(
      (prev, cur) => {
        if (Reflect.has(prev, cur)) {
          Reflect.deleteProperty(prev, cur);
        }
        return prev;
      },
      { ...initialFiltersData }
    );
    setFiltersData(initialFiltersData);

    const alertOpts = options.useCase.map((a, i) => ({
      ...a,
      ...actionOrder[i],
    }));

    const alertOptions = alertOpts.map((a) => {
      let icon: string | ReactNode = '';
      const style = {
        borderColor: color.primaryColor,
        marginRight: 0,
        padding: '0 4px',
        backgroundColor: 'transparent',
      };
      switch (a.value) {
        case '0':
          icon = (
            <Tag style={style}>
              <MenuUnfoldOutlined style={{ color: style.borderColor }} />
            </Tag>
          );
          break;
        case '1':
          icon = (
            <Tag style={style}>
              <RocketOutlined style={{ color: style.borderColor }} />
            </Tag>
          );
          break;
        case '2':
          icon = (
            <Tag style={style}>
              <UserSwitchOutlined style={{ color: style.borderColor }} />
            </Tag>
          );
          break;
        default:
          break;
      }
      return {
        ...a,
        label: (
          <Space>
            {icon}
            {locale.locale === 'ja' ? a.label : localeMapping[a.label]}
          </Space>
        ),
        icon: icon,
        text: locale.locale === 'ja' ? a.label : localeMapping[a.label],
      };
    });

    setOptOrgData({
      ...options,
      japanInvolved,
      consolidated,
      alertOptions,
      alertOpts,
      ...sliderOpts,
      memoTag,
      memoCreators,
    });
  };

  const [tableDataNeedFilter, setTableDataNeedFilter] = useState([]);
  const [optOrgData, setOptOrgData] = useState(null);
  const [optData, setOptData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [colFilter, setColFilter] = useState(initialColumnFilter);
  const [isUseCaseOne, setIsUseCaseOne] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  // 会計観点
  const [accountingPerspective, setAccountingPerspective] = useState<number>(2);
  const [accountingPerspectiveOpt, setAccountingPerspectiveOpt] = useState([
    {
      key: 1,
      value: 1,
      label: `${t('aipcmcty.page.consolidated')}＋${t('aipcmcty.page.equity')}`,
    },
    {
      key: 2,
      value: 2,
      label: t('aipcmcty.page.consolidated'),
    },
    {
      key: 3,
      value: 3,
      label: t('aipcmcty.page.toyoJConsolidated'),
    },
    {
      key: 4,
      value: 4,
      label: t('aipcmcty.page.baseUnit'),
    },
  ]);
  // 会計対象
  const [consolidated, setConsolidated] = useState();
  const [filtersData, setFiltersData] = useState({
    tgc: ['Toyo-J'],
    japanInvolved: null,
    consolidated: null,
    accountingYear: ['FY24'],
    useCase: null,
    scope: [],
    isExisted: null,
    isEpc: null,
    businessType: [],
    goodsType: [],
    priorityMatching: null,
    priorityDefault: [],
    budgetCategoryCustom: [],
    orderAmount: [],
    // grossMargin: [],
    imbalancedMh: null,
    won: [],
    phase: [],
    certainty: [],
    projectStartDate: null,
    projectEndDate: null,
    dxApplicable: null,
  });
  const [searchData, setSearchData] = useState({
    memoKeyWord: '',
    creator: [],
    createdAtStart: '',
    createdAtEnd: '',
    memoTag: [],
  });

  useEffect(() => {
    setOrgKpiInfos([]);
  }, []);

  useEffect(() => {
    const getTableData = async () => {
      setTableLoading(true);
      await initializeTableData();
      setTableLoading(false);
    };
    getTableData();
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    if (!optOrgData) {
      return;
    }
    const optOrgDataCopy = cloneDeep(optOrgData);
    switch (accountingPerspective) {
      // 連結＋持分
      case 1:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 連結
      case 2:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // Toyo-J連結
      case 3:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[0]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 拠点単体
      case 4:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[1]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: ['Toyo-J'],
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      default:
        setFiltersData((fData) => ({
          ...fData,
          tgc: ['Toyo-J'],
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
    }
    setOptData(optOrgDataCopy);
  }, [optOrgData, accountingPerspective]);

  useEffect(() => {
    if (!isNil(consolidated)) {
      getChartData();
    }
  }, [consolidated, snapshot, snapshotVersion]);

  const filterMethods = {
    search: (item, v = '') => item.projectName?.includes(v) || item.projectId.includes(v),
    accountingYear: (item, v) => !v?.length || v.some((i) => i.includes(item.accountingYear)),
    tgc: (item, v) => !v?.length || v.includes(item.tgc),
    japanInvolved: (item, v) => {
      if (v) {
        return item.japanInvolved === 'Toyo-J関与';
      }
      return true;
    },
    scope: (item, v) => !v?.length || v.includes(item.scope),
    isExisted: (item, v) => (v ? item.isExisted === v : true),
    isEpc: (item, v) => (v ? item.isEpc === v : true),
    businessType: (item, v) => !v?.length || v.includes(item.businessType),
    goodsType: (item, v) => !v?.length || v.includes(item.goodsType),
    budgetCategoryCustom: (item, v) => !v?.length || v.includes(item.budgetCategoryCustom ?? item.budgetCategory),
    priorityMatching: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
    priorityDefault: (item, v, k) => !v || (item[k] >= v[0] && item[k] <= v[1]),
    orderAmount: (item, v, k) => {
      const minLimit = 5000000000;
      const maxLimit = 60000000000;
      const sortedVString = v?.sort().join('');
      switch (sortedVString) {
        case 'lt50':
          return item[k] < minLimit;
        case 'gt600':
          return item[k] > maxLimit;
        case '50_600':
          return item[k] >= minLimit && item[k] <= maxLimit;
        case '50_600gt600':
          return item[k] >= minLimit;
        case '50_600lt50':
          return item[k] <= maxLimit;
        case 'gt600lt50':
          return item[k] > maxLimit || item[k] < minLimit;
        default:
          return true;
      }
    },
    // grossMargin: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
    imbalancedMh: (item, v, k) => {
      switch (v) {
        case '0':
          return item[k] === 0;
        case '1':
          return item[k] > 0;
        default:
          return true;
      }
    },
    won: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
    phase: (item, v) => !v?.length || v.includes(item.phase),
    certainty: (item, v) => !v?.length || v.includes(item.certainty),
    projectStartDate: (item, v) => {
      if (!v) {
        return true;
      }
      const { projectStartDate } = item;
      return moment(projectStartDate).isSameOrAfter(moment(v));
    },
    projectEndDate: (item, v) => {
      if (!v) {
        return true;
      }
      const { projectEndDate } = item;
      return moment(projectEndDate).isSameOrBefore(moment(v));
    },
    dxApplicable: (item, v, k) => !v || item[k] === Boolean(Number(v)),
  };

  // about filter
  const handleFilterChange = (k, v) => {
    setFiltersData((fData) => ({ ...fData, [k]: v }));
  };

  const [pageSize, setPageSize] = useState(50);
  const handlePageSizeChange = (pZ: number) => {
    setPageSize(pZ);
  };

  const handleAlertChange = (e, opt) => {
    const newFiltersData = opt
      ? {
          ...filtersData,
          ...formInitialValue.current,
          ...opt.strate,
          useCase: e,
        }
      : { ...filtersData, ...formInitialValue.current, useCase: e };
    setIsUseCaseOne(e === '1');
    setFiltersData(newFiltersData);
    form.setFieldsValue(newFiltersData);
    setColFilter(opt ? opt.columnFilter : initialColumnFilter);
    setKpiOrder(opt ? opt.kpiOrder : initialKpiOrder);
    setScrollCount(0);
  };

  const handleSiderFilterChange = debounce((changedValue) => {
    // メモ
    if (['memoKeyWord', 'creator', 'memoTag'].includes(Object.keys(changedValue)[0])) {
      setSearchData((sData) => ({ ...sData, ...changedValue }));
    }
    if (['createdAtStart', 'createdAtEnd'].includes(Object.keys(changedValue)[0])) {
      const newChangeValue = Object.keys(changedValue).reduce((acc, cur) => {
        if (changedValue[cur]) {
          acc[cur] = dayjs(changedValue[cur]).format('YYYY-MM-DD');
        } else {
          acc[cur] = '';
        }
        return acc;
      }, {});
      setSearchData((sData) => ({ ...sData, ...newChangeValue }));
    } else {
      setFiltersData((fData) => ({ ...fData, ...changedValue }));
    }
  }, 300);

  const handleExpandChange = async (projectId: string, expanded: boolean) => {
    const newTableData = [...tableDataNeedFilter];
    const expandItem = newTableData.find((d) => d.projectId === projectId);
    if (expanded) {
      if (!expandItem.children || expandItem.children.length === 0) {
        setTableLoading(true);
        const subProjects = (await APIList.getSubProjectCase().get({
          snapshot,
          snapshotVersion,
          projectId,
        })) as ProjectSetupTableItem[];
        subProjects.forEach((s) => {
          s.isChildren = true;
        });
        expandItem.children = subProjects;
        setExpandedRows([...expandedRows, projectId]);
        setTableLoading(false);
      } else {
        setExpandedRows([...expandedRows, projectId]);
      }
    } else {
      remove(expandedRows, (id) => id === projectId);
      setExpandedRows([...expandedRows]);
    }
    setTableDataNeedFilter(newTableData);
  };

  const formInitialValue = useRef(null);

  const [form] = Form.useForm();

  const [imbalancedMhOpt] = useState(
    [
      { label: '需給均衡', value: '0' },
      { label: '需給不足', value: '1' },
    ].map((item) => ({
      ...item,
      label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
    }))
  );

  const [priorityMatchingOpt] = useState([
    { label: `${t('aipcmcty.page.have')}`, value: true },
    { label: `${t('aipcmcty.page.no')}`, value: false },
  ]);

  const tabs = [
    {
      key: '1',
      label: t('aipcmcty.page.refinement'),
      children: (
        <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
          <Form {...layout} onValuesChange={handleSiderFilterChange} form={form} initialValues={filtersData}>
            <Divider>{t('aipcmcty.page.projectDivider')}</Divider>
            <Form.Item name="search" wrapperCol={{ span: 24 }}>
              <AutoComplete
                options={tableDataNeedFilter.map((t) => ({
                  label: t.projectName,
                  value: t.projectName,
                }))}
              >
                <Input.Search placeholder={`${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`} allowClear />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.serviceScope')} className="ellipsis">
                  {t('aipcmcty.page.serviceScope')}
                </div>
              }
              name="scope"
            >
              <Select allowClear mode="multiple" maxTagCount={1} options={optData?.scope} />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`} className="ellipsis">
                  {`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}
                </div>
              }
              name="isExisted"
            >
              <Select
                allowClear
                options={optData?.isExisted.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.nonEPC')}/EPC`} className="ellipsis">
                  {`${t('aipcmcty.page.nonEPC')}/EPC`}
                </div>
              }
              name="isEpc"
            >
              <Select
                allowClear
                options={optData?.isEpc.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.businessType')} className="ellipsis">
                  {t('aipcmcty.page.businessType')}
                </div>
              }
              name="businessType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.businessType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.productType')} className="ellipsis">
                  {t('aipcmcty.page.productType')}
                </div>
              }
              name="goodsType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.goodsType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.priority')} className="ellipsis">
                  {t('aipcmcty.page.priority')}
                </div>
              }
              name="priorityMatching"
            >
              <Select allowClear options={priorityMatchingOpt} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.priorityLevel')} className="ellipsis">
                  {t('aipcmcty.page.priorityLevel')}
                </div>
              }
              name="priorityDefault"
            >
              <Slider {...optData?.priorityDefaultSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.budgetCategory')} className="ellipsis">
                  {t('aipcmcty.page.budgetCategory')}
                </div>
              }
              name="budgetCategoryCustom"
            >
              <Select allowClear mode="multiple" options={optData?.budgetCategory} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.orderAmount')} className="ellipsis">
                  {t('aipcmcty.page.orderAmount')}
                </div>
              }
              name="orderAmount"
            >
              <Select
                allowClear
                mode="multiple"
                options={optData?.orderAmount.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
                maxTagCount={1}
              />
            </Form.Item>
            {/* <Form.Item
              label={
                <div title={t('aipcmcty.page.grossProfitRate')} className="ellipsis">
                  {t('aipcmcty.page.grossProfitRate')}
                </div>
              }
              name="grossMargin"
            >
              <Slider {...optData?.grossMarginSliderOpts} />
            </Form.Item> */}
            <Form.Item
              label={
                <div title={t('aipcmcty.page.demandSupplyFilterMH')} className="ellipsis">
                  {t('aipcmcty.page.demandSupplyFilterMH')}
                </div>
              }
              name="imbalancedMh"
            >
              <Select allowClear options={imbalancedMhOpt} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title="Won%" className="ellipsis">
                  Won%
                </div>
              }
              name="won"
            >
              <Slider {...optData?.wonSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.phase')} className="ellipsis">
                  {t('aipcmcty.page.phase')}
                </div>
              }
              name="phase"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.phase.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.accuracy')} className="ellipsis">
                  {t('aipcmcty.page.accuracy')}
                </div>
              }
              name="certainty"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.certainty.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.startDate')} className="ellipsis">
                  {t('aipcmcty.page.startDate')}
                </div>
              }
              name="projectStartDate"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.endDate')} className="ellipsis">
                  {t('aipcmcty.page.endDate')}
                </div>
              }
              name="projectEndDate"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.dxApplication')} className="ellipsis">
                  {t('aipcmcty.page.dxApplication')}
                </div>
              }
              name="dxApplicable"
            >
              <Select
                allowClear
                options={optData?.dxApplicable.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Divider>{t('aipcmcty.page.memoDivider')}</Divider>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.keyWord')} className="ellipsis">
                  {t('aipcmcty.page.memo.keyWord')}
                </div>
              }
              name="memoKeyWord"
            >
              <Input.Search allowClear />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.author')} className="ellipsis">
                  {t('aipcmcty.page.memo.author')}
                </div>
              }
              name="creator"
            >
              <Select allowClear mode="multiple" options={optData?.memoCreators} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.creationPeriod')} className="ellipsis">
                  {t('aipcmcty.page.memo.creationPeriod')}
                </div>
              }
            >
              <Form.Item name="createdAtStart">
                <DatePicker />
              </Form.Item>
              <Form.Item name="createdAtEnd">
                <DatePicker />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.tag')} className="ellipsis">
                  {t('aipcmcty.page.memo.tag')}
                </div>
              }
              name="memoTag"
            >
              <Select allowClear mode="multiple" options={optData?.memoTag} />
            </Form.Item>
          </Form>
        </CustomerScrollBar>
      ),
    },
  ];

  useEffect(() => {
    initializeTableData();
  }, [searchData]);

  // about formations modal and table
  const [isFormationModalOpen, setFormationModalOpen] = useState(false);
  const [curTableItem, setCurTableItem] = useState<ProjectSetupTableItem>(null);
  const [formationTableLoading, setFormationTableLoading] = useState(false);

  const calcFeedActive = (item: ProjectSetupTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[0] === 'effimate';
      default:
        return false;
    }
  };

  const calcEpcActive = (item: ProjectSetupTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return !scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[1] === 'effimate';
      default:
        return false;
    }
  };

  const isFeedActive = calcFeedActive(curTableItem);
  const isEpcActive = calcEpcActive(curTableItem);

  const getRatio = (ratioStr: string | null | undefined) => {
    if (!ratioStr) {
      return [null, null];
    }
    const reg = /^Toyo-J:(\d+)\|Toyo-I:(\d+)$/;
    const { 1: japanRatio, 2: indiaRatio } = ratioStr.match(reg);
    return [Number(japanRatio), Number(indiaRatio)];
  };
  const [tgcFeedJE, tgcFeedIE] = getRatio(curTableItem?.formTgcFeedE);
  const [tgcEpcJE, tgcEpcIE] = getRatio(curTableItem?.formTgcEpcE);
  const [tgcFeedJP, tgcFeedIP] = getRatio(curTableItem?.formTgcFeedP);
  const [tgcEpcJP, tgcEpcIP] = getRatio(curTableItem?.formTgcEpcP);
  const [tgcFeedJC, tgcFeedIC] = getRatio(curTableItem?.formTgcFeedC);
  const [tgcEpcJC, tgcEpcIC] = getRatio(curTableItem?.formTgcEpcC);
  // const [tgcFeedJPjt, tgcFeedIPjt] = getRatio(curTableItem?.formTgcFeedPjt);
  // const [tgcEpcJPjt, tgcEpcIPjt] = getRatio(curTableItem?.formTgcEpcPjt);

  const formationTableData = [
    {
      formType: 'E',
      jvFeed: curTableItem?.formJvFeedE,
      tgcFeedJ: tgcFeedJE,
      tgcFeedI: tgcFeedIE,
      jvEpc: curTableItem?.formJvEpcE,
      tgcEpcJ: tgcEpcJE,
      tgcEpcI: tgcEpcIE,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'P',
      jvFeed: curTableItem?.formJvFeedP,
      tgcFeedJ: tgcFeedJP,
      tgcFeedI: tgcFeedIP,
      jvEpc: curTableItem?.formJvEpcP,
      tgcEpcJ: tgcEpcJP,
      tgcEpcI: tgcEpcIP,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'C',
      jvFeed: curTableItem?.formJvFeedC,
      tgcFeedJ: tgcFeedJC,
      tgcFeedI: tgcFeedIC,
      jvEpc: curTableItem?.formJvEpcC,
      tgcEpcJ: tgcEpcJC,
      tgcEpcI: tgcEpcIC,
      isFeedActive,
      isEpcActive,
    },
    // {
    //   formType: 'PJT',
    //   jvFeed: curTableItem?.formJvFeedPjt,
    //   tgcFeedJ: tgcFeedJPjt,
    //   tgcFeedI: tgcFeedIPjt,
    //   jvEpc: curTableItem?.formJvEpcPjt,
    //   tgcEpcJ: tgcEpcJPjt,
    //   tgcEpcI: tgcEpcIPjt,
    //   isFeedActive: false,
    //   isEpcActive: false
    // },
  ];
  // const getOpts = (elem, type) => {
  //   const { formType } = elem;
  //   switch (true) {
  //     case type === 'JV_FEED':
  //     case type === 'JV_EPC':
  //       return optData?.formJV.map(f => ({ ...f, value: Number(f.value) }));
  //     case formType === 'E' && type === 'TGC_FEED':
  //       return optData?.formTGCFeedE;
  //     case formType === 'P' && type === 'TGC_FEED':
  //       return optData?.formTGCFeedP;
  //     case formType === 'C' && type === 'TGC_FEED':
  //       return optData?.formTGCFeedC;
  //     case formType === 'PJT' && type === 'TGC_FEED':
  //       return optData?.formTGCFeedPJT;
  //     case formType === 'E' && type === 'TGC_EPC':
  //       return optData?.formTGCEpcE;
  //     case formType === 'P' && type === 'TGC_EPC':
  //       return optData?.formTGCEpcP;
  //     case formType === 'C' && type === 'TGC_EPC':
  //       return optData?.formTGCEpcC;
  //     case formType === 'PJT' && type === 'TGC_EPC':
  //       return optData?.formTGCEpcPJT;
  //     default:
  //       return [];
  //   }
  // };

  const [cachedFormation, setCachedFormation] = useState(null);

  const handleFormationJVChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    switch (true) {
      case type === 'JV_FEED' && formType === 'E':
        newItem.formJvFeedE = e;
        break;
      case type === 'JV_FEED' && formType === 'P':
        newItem.formJvFeedP = e;
        break;
      case type === 'JV_FEED' && formType === 'C':
        newItem.formJvFeedC = e;
        break;
      case type === 'JV_FEED' && formType === 'PJT':
        newItem.formJvFeedPjt = e;
        break;
      case type === 'JV_EPC' && formType === 'E':
        newItem.formJvEpcE = e;
        break;
      case type === 'JV_EPC' && formType === 'P':
        newItem.formJvEpcP = e;
        break;
      case type === 'JV_EPC' && formType === 'C':
        newItem.formJvEpcC = e;
        break;
      case type === 'JV_EPC' && formType === 'PJT':
        newItem.formJvEpcPjt = e;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const handleFormationTGCChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    const testNil = e === null || e === undefined || e === '';
    switch (true) {
      case type === 'TGC_FEED' && formType === 'E':
        newItem.formTgcFeedE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'P':
        newItem.formTgcFeedP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'C':
        newItem.formTgcFeedC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'PJT':
        newItem.formTgcFeedPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'E':
        newItem.formTgcEpcE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'P':
        newItem.formTgcEpcP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'C':
        newItem.formTgcEpcC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'PJT':
        newItem.formTgcEpcPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const formationTableCols: ColumnsType<any> = [
    {
      dataIndex: 'formType',
      key: 'formType',
      align: 'center',
      width: 50,
    },
    {
      title: 'FEED',
      dataIndex: 'FEED',
      key: 'FEED',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvFeed',
          key: 'jvFeed',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcFeedJ',
          key: 'tgcFeedJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcFeedI',
          key: 'tgcFeedI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
              />
            );
          },
        },
      ],
    },
    {
      title: 'EPC',
      dataIndex: 'EPC',
      key: 'EPC',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvEpc',
          key: 'jvEpc',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcEpcJ',
          key: 'tgcEpcJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcEpcI',
          key: 'tgcEpcI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                disabled
              />
            );
          },
        },
      ],
    },
  ];

  const demandForecastAction = (item) => {
    setTableLoading(true);
    setKpiLoadingStatus(true);
    APIList.demandForecastAction()
      .post({
        projectId: item.projectId,
        snapshotVersion,
        snapshot,
      })
      .then((res) => {
        const { sessionId } = res as any;
        let timeoutTimer = setTimeout(async () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
            message.error('Connection timeout.');
            setTableLoading(false);
            setKpiLoadingStatus(false);
          }
        }, timeout);
        let timer = setInterval(() => {
          APIList.getSimulationStatus()
            .get({ sessionId })
            .then(async (r: any) => {
              if (r.status === 'forecastDone') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                getChartData();
                await initializeTableData();
                setTableLoading(false);
                message.success(t('aipcmcty.page.updateScheduleProjectId').replace('${item.projectId}', item.projectId));
                console.log('forecastDone');
              } else if (r.status === 'forecastError') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                setTableLoading(false);
                setKpiLoadingStatus(false);
                message.error('Forecast Failed.');
                console.log('forecastError');
              }
            })
            .catch(() => {
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              setTableLoading(false);
              setKpiLoadingStatus(false);
              message.error('Forecast Failed.');
              console.log('forecastError');
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        message.error('Connecting timeout.');
        setTableLoading(false);
        setKpiLoadingStatus(false);
      });
  };

  const diffMap = {
    grossMargin: (p, n) => Number(p).toFixed(3) !== Number(n).toFixed(3),
    orderAmount: (p, n) => Number(p).toFixed(0) !== Number(n).toFixed(0),
    grossProfit: (p, n) => Number(p).toFixed(0) !== Number(n).toFixed(0),
  };

  const isDiff = (before, after, key) => {
    const diffFunc = diffMap[key];
    if (diffFunc) {
      return diffFunc(before, after);
    }
    return before !== after;
  };

  // about table
  const handleTableChange = (elem: string, value: any, id: string) => {
    const newTableData = [...tableDataNeedFilter];
    const item = newTableData.find((t) => t.id === id);
    if (isDiff(item[elem], value, elem)) {
      const cachedHistory: RecordItem = {
        projectId: item.projectId,
        before: item[elem],
        after: value,
        field: elem,
        type: 'CHANGE',
        snapshot,
        snapshotVersion,
        createdBy: user,
        projectUuid: item.id,
        hasEffimate: item.demandForecastType?.includes('effimate'),
      };
      const beforeValue = item[elem];
      item[elem] = value;
      const changeMethod = tableChangeMap[elem];
      if (changeMethod) {
        if (elem === 'projectStartDate') {
          changeMethod(item, moment(value).diff(moment(beforeValue), 'days'));
        } else if (elem === 'phase') {
          changeMethod(item, optData?.wonCalcBackup);
        } else {
          changeMethod(item);
        }
      }

      APIList.updateProjectCase()
        .put({ ...item })
        .then(async () => {
          message.success(t('aipcmcty.page.updateProjectId').replace('${item.projectId}', item.projectId));
          APIList.postOperationHistory().post({ histories: [cachedHistory] });
          if (expandedRows.includes(item.projectId)) {
            setTableLoading(true);
            const subProjects = (await APIList.getSubProjectCase().get({
              snapshot,
              snapshotVersion,
              projectId: item.projectId,
            })) as ProjectSetupTableItem[];
            subProjects.forEach((s) => {
              s.isChildren = true;
            });
            item.children = subProjects;
            setExpandedRows([...expandedRows, item.projectId]);
            setTableLoading(false);
          }
          if (item.demandForecastType?.includes('effimate') && elem === 'dxApplicable') {
            demandForecastAction(item);
          }
        });
      setTableDataNeedFilter(newTableData);
    }
  };

  const handleTableReset = (id) => {
    const keysNeedReset = [
      'budgetCategory',
      'priority',
      'orderAmount',
      'grossProfit',
      'grossMargin',
      'phase',
      'projectStartDate',
      'projectEndDate',
      'duration',
    ];
    const newTableData = [...tableDataNeedFilter];
    const item = newTableData.find((t) => t.id === id);
    const cachedItem = cloneDeep(item);
    keysNeedReset.forEach((k) => {
      if (k === 'budgetCategory') {
        item.budgetCategoryCustom = item[`${k}Default`];
      } else {
        item[k] = item[`${k}Default`];
      }
      if (k === 'phase') {
        tableChangeMap[k](item, optData?.wonCalcBackup);
      }
    });
    const resetDiff: RecordItem[] = [];
    Object.keys(cachedItem).forEach((k) => {
      if (cachedItem[k] !== item[k] && k !== 'children') {
        resetDiff.push({
          before: cachedItem[k],
          after: item[k],
          projectId: item.projectId,
          field: k,
          type: 'RESET',
          snapshot,
          snapshotVersion,
          createdBy: user,
          projectUuid: cachedItem.id,
          hasEffimate: cachedItem.demandForecastType?.includes('effimate'),
        });
      }
    });
    APIList.resetProjectCase()
      .post({ ...item })
      .then(async () => {
        message.success(t('aipcmcty.page.resetProjectId').replace('${item.projectId}', item.projectId));
        APIList.postOperationHistory().post({ histories: resetDiff });
        if (expandedRows.includes(item.projectId)) {
          const subProjects = (await APIList.getSubProjectCase().get({
            snapshot,
            snapshotVersion,
            projectId: item.projectId,
          })) as ProjectSetupTableItem[];
          subProjects.forEach((s) => {
            s.isChildren = true;
          });
          item.children = subProjects;
          setExpandedRows([...expandedRows, item.projectId]);
        }
      });
    setTableDataNeedFilter(newTableData);
  };

  const filteredTableData = useMemo(() => {
    const newTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...tableDataNeedFilter]
    );

    if (filtersData.useCase === '1') {
      newTableData.forEach((d, i) => {
        if (i === 0) {
          d.orderAccumulation = d.orderAmount;
          d.profitAccumulation = d.grossProfit;
        } else {
          d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
          d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
        }
      });
      return newTableData;
    }
    const priorities = newTableData.filter((t) => t.priorityMatching).sort((a, b) => a.priorityDefault - b.priorityDefault);
    const normals = newTableData.filter((t) => !t.priorityMatching).sort((a, b) => a.priorityDefault - b.priorityDefault);
    return [...priorities, ...normals].map((d, i) => {
      if (i === 0) {
        d.orderAccumulation = d.orderAmount;
        d.profitAccumulation = d.grossProfit;
      } else {
        d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
        d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
      }
      return { ...d };
    });
  }, [filtersData, tableDataNeedFilter]);

  // about modal
  const [curModalItem, setCurModalItem] = useState<any>();
  const [needRefresh, setNeedRefresh] = useState(false);
  const [mhPilesChartData, setMhPilesChartData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectSetUpDetailTableData, setProjectSetUpDetailTableData] = useState([]);
  useEffect(() => {
    const data = [];
    const dateList = [
      '2021/01',
      '2021/02',
      '2021/03',
      '2021/04',
      '2021/05',
      '2021/06',
      '2021/07',
      '2021/08',
      '2021/09',
      '2021/10',
      '2021/11',
      '2021/12',
      '2022/01',
      '2022/02',
      '2022/03',
      '2022/04',
      '2022/05',
      '2022/06',
      '2022/07',
      '2022/08',
      '2022/09',
      '2022/10',
      '2022/11',
      '2022/12',
      '2023/01',
      '2023/02',
      '2023/03',
      '2023/04',
      '2023/05',
      '2023/06',
      '2023/07',
      '2023/08',
      '2023/09',
      '2023/10',
      '2023/11',
      '2023/12',
      '2024/01',
      '2024/02',
      '2024/03',
      '2024/04',
      '2024/05',
      '2024/06',
      '2024/07',
      '2024/08',
      '2024/09',
      '2024/10',
      '2024/11',
      '2024/12',
      '2025/01',
      '2025/02',
      '2025/03',
      '2025/04',
      '2025/05',
      '2025/06',
      '2025/07',
      '2025/08',
      '2025/09',
      '2025/10',
      '2025/11',
      '2025/12',
    ];
    for (let i = 0; i < 50; i++) {
      data.push({
        discipline: 'discipline1',
        value: 100 + Math.floor(Math.random() * (10 - -10 + 1)) + -10,
        date: dateList[i],
      });
    }
    for (let i = 0; i < 50; i++) {
      data.push({
        discipline: 'discipline2',
        value: 10 + Math.floor(Math.random() * (10 - -10 + 1)) + -10,
        date: dateList[i],
      });
    }
    for (let i = 0; i < 50; i++) {
      data.push({
        discipline: 'discipline3',
        value: 20 + Math.floor(Math.random() * (10 - -10 + 1)) + -10,
        date: dateList[i],
      });
    }
    for (let i = 0; i < 50; i++) {
      data.push({
        discipline: 'discipline4',
        value: 30 + Math.floor(Math.random() * (10 - -10 + 1)) + -10,
        date: dateList[i],
      });
    }
    setMhPilesChartData(data);

    setProjectSetUpDetailTableData([
      {
        key: 'data01',
        phase: 'Engineering',
        discipline: 'PIP',
        category: 'Layout',
        stage: '30%MR',
        role: 'Role1',
        standardWorkHours: 200,
        type: '拠点',
        japan: '20%',
        india: '80%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
      {
        key: 'data02',
        phase: 'Engineering',
        discipline: 'PIP',
        category: 'Layout',
        stage: '30%MR',
        role: 'Role2',
        standardWorkHours: 800,
        type: '拠点',
        japan: '20%',
        india: '80%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
      {
        key: 'data03',
        phase: 'SCM',
        discipline: 'PIP',
        category: 'Commodity',
        stage: 'Inquiry',
        role: 'Role5',
        standardWorkHours: 600,
        type: 'OS',
        japan: '0%',
        india: '0%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
      {
        key: 'data04',
        phase: 'SCM',
        discipline: 'PIP',
        category: 'Commodity',
        stage: 'Inquiry',
        role: 'Role6',
        standardWorkHours: 400,
        type: 'OS',
        japan: '0%',
        india: '0%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
      {
        key: 'data05',
        phase: 'Construction',
        discipline: 'PIP',
        category: 'Process',
        stage: 'UG',
        role: 'Role1',
        standardWorkHours: 2000,
        type: '拠点',
        japan: '20%',
        india: '80%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
      {
        key: 'data06',
        phase: 'Construction',
        discipline: 'PIP',
        category: 'Process',
        stage: 'UG',
        role: 'Role2',
        standardWorkHours: 1500,
        type: '拠点',
        japan: '20%',
        india: '80%',
        'Japan-2014/01': 6,
        'Japan-2014/02': 6,
        'Japan-2014/03': 6,
        'Japan-2014/04': 6,
        'Japan-2014/05': 6,
        'Japan-2014/06': 6,
        'Japan-2014/07': 6,
        'Japan-2014/08': 6,
        'Japan-2014/09': 6,
        'India-2014/01': 8,
        'India-2014/02': 8,
        'India-2014/03': 8,
        'India-2014/04': 8,
        'India-2014/05': 8,
        'India-2014/06': 8,
        'India-2014/07': 8,
        'India-2014/08': 8,
        'India-2014/09': 8,
      },
    ]);
  }, []);

  useEffect(() => {
    if (needRefresh && curModalItem) {
      demandForecastAction(curModalItem);
      setNeedRefresh(false);
    }
  }, [curModalItem, needRefresh]);

  // about chart
  const { kpiInfos, initKpiData, getKpiData, setKpiLoadingStatus, filterKpiData, orgKpiInfos } = useContext(AipcmctyContext);
  const [kpiMapping, setKpiMapping] = useState<any>();
  const [kpiOrder, setKpiOrder] = useState([]);
  const [kpiChartOnlyOrder, setKpiChartOnlyOrder] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [riskMapData, setRiskMapData] = useState<any>([]);

  // const [riskMapLoading, setRiskMapLoading] = useState<any>(true);
  const [casesPercentageLayout] = useState({
    xLabels: [
      [t('aipcmcty.page.strategic')],
      [t('aipcmcty.page.continuity'), t('aipcmcty.page.futureDevelopment')],
      [
        t('aipcmcty.page.existing'),
        t('aipcmcty.page.existing'),
        t('aipcmcty.page.existing'),
        `${t('aipcmcty.page.new')}/${t('aipcmcty.page.futurePotential')}`,
      ],
    ],
    yLabels: [['顾客関係'], [t('aipcmcty.page.excellent'), t('aipcmcty.page.good'), t('aipcmcty.page.normal'), t('aipcmcty.page.general')]],
  });
  useEffect(() => {
    // Chartデータをセット
    setKpiOrder(initialKpiOrder);
    setKpiChartOnlyOrder([
      [5, 7, 9, 10, 11, 12, 15, 34, 16, 17, 18],
      [22, 23, 24, 25],
      [27, 28, 29, 30],
    ]);
    setTimeout(() => {
      setChartLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setKpiMapping({
      1.1: (
        <TurnoverChart
          kpiCode="KMT001"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KMT001').data}
          loading={getKpiData('KMT001').loading}
          title={customChartGroupTitle(t('aipcmcty.page.midtermKgi'), t('aipcmcty.page.consolidatedRevenue'), color.primaryColor)}
        />
      ),
      2.1: (
        <TurnoverChart
          kpiCode="KMT002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KMT002').data}
          loading={getKpiData('KMT002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.midtermKgi'), t('aipcmcty.page.consolidatedGrossProfit'), color.primaryColor)}
        />
      ),
      3.1: (
        <TurnoverChart
          kpiCode="KMT003"
          data={getKpiData('KMT003').data}
          loading={getKpiData('KMT003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.midtermKgi'), t('aipcmcty.page.consolidatedNetProfit'), color.primaryColor)}
        />
      ),
      1.2: (
        <GrossProfitCompositionChart
          kpiCode="KMT101"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KMT101').data}
          loading={getKpiData('KMT101').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.midtermKpi'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.consolidatedGrossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
              : `${t('aipcmcty.page.nonEPC')}/EPC ${t('aipcmcty.page.consolidatedGrossProfitComposition')}`,
            color.primaryColor
          )}
        />
      ),
      2.2: (
        <GrossProfitCompositionChart
          kpiCode="KMT102"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KMT102').data}
          loading={getKpiData('KMT102').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.midtermKpi'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.consolidatedGrossProfitComposition')}(${t('aipcmcty.page.newBusiness')}/${t(
                  'aipcmcty.page.existingBusiness'
                )})`
              : `${t('aipcmcty.page.newBusiness')}/${t('aipcmcty.page.existingBusiness')} ${t(
                  'aipcmcty.page.consolidatedGrossProfitComposition'
                )}`,
            color.primaryColor
          )}
        />
      ),
      3.2: (
        <GrossProfitCompositionChart
          kpiCode="KMT103"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KMT103').data}
          loading={getKpiData('KMT103').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.midtermKpi'),
            t('aipcmcty.page.consolidatedMainBaseGrossProfitComposition'),
            color.primaryColor
          )}
        />
      ),
      4: (
        <StrategyMatrixChart
          kpiCode="KPJ001"
          data={getKpiData('KPJ001').data}
          loading={getKpiData('KPJ001').loading}
          layoutOptions={{
            xLabels: [
              [t('aipcmcty.page.grossProfitMargin')],
              [t('aipcmcty.page.lessThan8Percent'), '8%〜12%', t('aipcmcty.page.moreThan12Percent')],
            ],
            yLabels: [
              [
                {
                  label: t('aipcmcty.page.growth'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: '',
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: `（${t('aipcmcty.page.innovation')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },

                {
                  label: `（${t('aipcmcty.page.adjacentArea')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },

                {
                  label: t('aipcmcty.page.existing'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
            ],
            gridTop: 35,
            xHeight: 65,
            chartTop: 25,
          }}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.valueCreation'), color.successColor)}
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
        />
      ),
      5: (
        <OrderAmountChart
          kpiCode="KPJ002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ002').data}
          loading={getKpiData('KPJ002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderAmount'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      6: (
        <OrderGrossProfit
          kpiCode="KPJ020"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ020').data}
          loading={getKpiData('KPJ020').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderGrossProfit'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      7: (
        <TurnoverChart
          kpiCode="KPJ003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ003').data}
          loading={getKpiData('KPJ003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.sales'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      8: (
        <TurnoverChart
          kpiCode="KPJ004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ004').data}
          loading={getKpiData('KPJ004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.expectedGrossProfitAmt'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      9: (
        <GrossProfitCompositionChart
          kpiCode="KPJ005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ005').data}
          loading={getKpiData('KPJ005').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
              : `${t('aipcmcty.page.nonEPC')}/EPC ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      10: (
        <GrossProfitCompositionChart
          kpiCode="KPJ006"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ006').data}
          loading={getKpiData('KPJ006').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')})`
              : `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existingBusiness')} ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      11: (
        <GrossProfitCompositionChart
          kpiCode="KPJ007"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ007').data}
          loading={getKpiData('KPJ007').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(Japan/${t('aipcmcty.page.others')})`
              : `Japan/${t('aipcmcty.page.others')} ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      12: (
        <GrossProfitCompositionChart
          kpiCode="KPJ008"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ008').data}
          loading={getKpiData('KPJ008').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t(
                  'aipcmcty.page.JV'
                )})`
              : `${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t('aipcmcty.page.JV')} ${t(
                  'aipcmcty.page.grossProfitComposition'
                )}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      15: (
        <GrossProfitMargin
          kpiCode="KPJ011"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ011').data}
          loading={getKpiData('KPJ011').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitAndRate'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      16: (
        <GrossProfitPerMhChart
          kpiCode="KPJ012"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ012').data}
          loading={getKpiData('KPJ012').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitPerMH'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      17: (
        <TimeValueChart
          kpiCode="KPJ013"
          data={getKpiData('KPJ013').data}
          loading={getKpiData('KPJ013').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.NPVgrossProfitRate'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      18: (
        <WonPercentChart
          kpiCode="KPJ014"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ014').data}
          loading={getKpiData('KPJ014').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), 'Won％', color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      19: (
        <CasesPercentageChart
          layoutOptions={casesPercentageLayout}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.strategicProjectRatio'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      // 20: <RiskMapChart
      //   data={riskMapData} loading={riskMapLoading} title={customChartGroupTitle(t('aipcmcty.page.project'), 'リスクマップ', color.successColor)} ribbonText={t('aipcmcty.page.quality')}
      //   ribbonColor={color.primaryColor}
      // />,
      21: (
        <StrategyMatrixChart
          kpiCode="KRS001"
          data={getKpiData('KRS001').data}
          loading={getKpiData('KRS001').loading}
          layoutOptions={{
            xLabels: [
              [t('aipcmcty.page.profitPerPersonAmount')],
              [t('aipcmcty.page.lessThan0Yen'), t('aipcmcty.page.0To150MillionYen'), t('aipcmcty.page.over150MillionYen')],
            ],
            yLabels: [
              [
                {
                  label: t('aipcmcty.page.growth'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: '',
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: `（${t('aipcmcty.page.innovation')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: `（${t('aipcmcty.page.adjacentArea')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.existing'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
            ],
            gridTop: 35,
            xHeight: 65,
            chartTop: 25,
          }}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resourceCapability'), color.warningColor, {
            fontSize: 14,
          })}
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
        />
      ),
      22: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS002').data}
          loading={getKpiData('KRS002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.ESMHutilizationRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      23: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS003').data}
          loading={getKpiData('KRS003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.demandSupplyGapMH'), color.warningColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      24: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS004').data}
          loading={getKpiData('KRS004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.jobRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      25: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS005').data}
          loading={getKpiData('KRS005').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.talentTurnoverRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      26: (
        <StrategyMatrixChart
          kpiCode="ValueProposition"
          data={getKpiData('ValueProposition').data}
          loading={getKpiData('ValueProposition').loading}
          title={
            <span
              key={t('aipcmcty.page.valueDelivery')}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 3,
              }}
            >
              <Tag color={color.successColor} style={{ margin: 0 }}>
                {t('aipcmcty.page.project')}
              </Tag>
              <Tag color={color.warningColor} style={{ margin: 0 }}>
                {t('aipcmcty.page.resource')}
              </Tag>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  marginLeft: 5,
                }}
              >
                {t('aipcmcty.page.valueDelivery')}
              </div>
            </span>
          }
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
        />
      ),
      27: (
        <HumanResourcesTurnoverChart
          kpiCode="KDX002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX002').data}
          loading={getKpiData('KDX002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.DXapplicationRatio'), color.errorColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      28: (
        <TurnoverChart
          kpiCode="KDX003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX003').data}
          loading={getKpiData('KDX003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.dxAllowanceFullPot'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      29: (
        <HumanResourcesTurnoverChart
          kpiCode="KDX004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX004').data}
          loading={getKpiData('KDX004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.DXallowanceDiscountRate'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      30: (
        <TurnoverChart
          kpiCode="KDX005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX005').data}
          loading={getKpiData('KDX005').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.dxAllowanceAmount'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
      31: (
        <SupplyDemandChart
          kpiCode="KOT001"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KOT001').data}
          loading={getKpiData('KOT001').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan)`
              : `Japan ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
        />
      ),
      32: (
        <SupplyDemandChart
          kpiCode="KOT002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KOT002').data}
          loading={getKpiData('KOT002').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(India)`
              : `India ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
        />
      ),
      33: (
        <SupplyDemandChart
          kpiCode="KOT003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KOT003').data}
          loading={getKpiData('KOT003').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan + India)`
              : `Japan + India ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
        />
      ),
      34: (
        <ProjectGrossProfitChart
          data={tableDataNeedFilter}
          tgcList={filtersData.tgc}
          accountingYears={filtersData.accountingYear}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.projectGrossProfitExpectedList'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
        />
      ),
    });
  }, [kpiInfos, filtersData, fiscalQuarter, periodSwitch]);

  useEffect(() => {
    setTimeout(() => {
      filterKpiData({
        tgc: filtersData.tgc,
        japanInvolved: filtersData.japanInvolved,
        consolidated,
      });
    }, 0);
  }, [orgKpiInfos, filtersData]);

  const getChartData = () => {
    // setRiskMapLoading(true);

    // initKpiData(['KPJ002', 'KPJ011', 'KPJ003', 'KPJ004', 'KPJ005', 'KPJ006', 'KPJ007', 'KPJ008', 'KPJ012', 'KRS002', 'KRS003', 'KDX003', 'KDX005', 'KRS004', 'KRS005', 'KDX004', 'KDX002', 'KPJ013', 'KPJ014', 'KPJ001', 'KRS001', 'ValueProposition', 'KOT001', 'KOT002']);
    initKpiData(
      [
        [
          'KPJ002',
          'KPJ011',
          'KPJ003',
          'KPJ004',
          'KPJ005',
          'KPJ006',
          'KPJ007',
          'KPJ008',
          'KPJ012',
          'KPJ013',
          'KPJ014',
          'KPJ001',
          'KRS001',
          'ValueProposition',
        ],
        ['KRS002', 'KRS003', 'KRS004', 'KRS005', 'KPJ020'],
        ['KDX002'],
        ['KDX003', 'KDX004', 'KDX005'],
        ['KOT001'],
        ['KOT002'],
        ['KOT003'],
        ['KMT001', 'KMT002', 'KMT003', 'KMT101', 'KMT102', 'KMT103'],
      ],
      !!consolidated
    );
    // APIList.getRiskMap().get().then((data: any[]) => {
    //   setRiskMapData(data);
    //   setRiskMapLoading(false);
    // });
  };

  const startDsSimulation = () => {
    setTableLoading(true);
    setKpiLoadingStatus(true);
    APIList.dsSimulationAction()
      .post({
        snapshot,
        snapshotVersion,
      })
      .then((resp: any) => {
        const { sessionId } = resp;
        let timeoutTimer = setTimeout(async () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
            message.error('Connection Timeout');
            setTableLoading(false);
            setKpiLoadingStatus(false);
          }
        }, timeout);
        let timer = setInterval(() => {
          APIList.getSimulationStatus()
            .get({ sessionId })
            .then(async (r: any) => {
              if (r.status === 'forecastDone') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                getChartData();
                await initializeTableData();
                setTableLoading(false);
                message.success(t('aipcmcty.page.demandSupplyMatchingCompleted'));
                console.log('forecastDone');
              } else if (r.status === 'forecastError') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                setTableLoading(false);
                setKpiLoadingStatus(false);
                message.error('Forecast Failed.');
                console.log('forecastError');
              }
            })
            .catch((err) => {
              console.log(err);
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              message.success('Forecast Failed.');
              setTableLoading(false);
              setKpiLoadingStatus(false);
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        message.error('Forecast Failed.');
        setTableLoading(false);
        setKpiLoadingStatus(false);
      });
  };

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const chartContent = () => {
    if (viewMode === 'chart-only') {
      return (
        <>
          {kpiMapping ? (
            kpiChartOnlyOrder.map((kpiArr) => (
              <ChartGroup
                key={kpiArr}
                viewMode={viewMode}
                loading={chartLoading}
                isScrollChart
                menuCollapsed={menuCollapsed}
                scrollCount={scrollCount}
                setScrollCount={setScrollCount}
                selectorTop={selectorTop}
                sizeMode={sizeMode}
              >
                {kpiArr.map((kpi) => (
                  <ChartGroup.Item key={`cartOnly-${kpi}`}>{kpiMapping[kpi]}</ChartGroup.Item>
                ))}
              </ChartGroup>
            ))
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <ChartGroup
          loading={false}
          syncScroll
          menuCollapsed={menuCollapsed}
          scrollCount={scrollCount}
          setScrollCount={setScrollCount}
          selectorTop={selectorTop}
          sizeMode={sizeMode}
        >
          {kpiOrder.map((kpi) => (
            <ChartGroup.Item key={`chartTable-${kpi}`}>{kpiMapping[kpi]}</ChartGroup.Item>
          ))}
        </ChartGroup>
      );
    }
  };

  return (
    <>
      <ProjectSetupContext.Provider
        value={{
          handleTableChange,
          handleTableReset,
          setIsModalOpen,
          setMemoCollapsed,
          setMemoInfo,
          setMenuCollapsed,
          setFilterCollapsed,
          setFormationModalOpen,
          setCurTableItem,
          optData,
          setCurModalItem,
          isUseCaseOne,
          calcFeedActive,
          setCachedFormation,
          filtersData,
          accountingPerspective,
          accountingPerspectiveOpt,
          imbalancedMhOpt,
          priorityMatchingOpt,
          searchData
        }}
      >
        <div className="dashboard project-setup" style={{ height: 'calc(100vh - 85px)' }}>
          <div
            style={{
              marginRight: filterCollapsed && memoCollapsed ? 0 : 340,
            }}
          >
            <Row justify="space-between" className="operation-container">
              <Col style={{ padding: '5px 0' }}>
                <Space>
                  {`${t('aipcmcty.page.accountingPerspective')}:`}
                  <Select
                    allowClear
                    style={{ width: 200 }}
                    value={accountingPerspective}
                    maxTagCount="responsive"
                    options={accountingPerspectiveOpt}
                    onChange={(v) => {
                      setAccountingPerspective(v);
                    }}
                  />
                  TGC:
                  <Select
                    style={{ width: 180 }}
                    value={filtersData.tgc}
                    mode="multiple"
                    maxTagCount="responsive"
                    options={optData?.tgc}
                    onChange={(vs) => {
                      handleFilterChange('tgc', vs);
                    }}
                  />
                  {/* 日本関与:
                  <Select
                    style={{ width: 80 }}
                    value={filtersData.japanInvolved}
                    maxTagCount="responsive"
                    options={optData?.japanInvolved}
                    onChange={vs => {
                      handleFilterChange('japanInvolved', vs);
                    }}
                  />
                  会計対象:
                  <Select
                    style={{ width: 80 }}
                    value={consolidated}
                    maxTagCount="responsive"
                    options={optData?.consolidated}
                    onChange={v => {
                      setConsolidated(v);
                    }}
                  /> */}
                  {`${t('aipcmcty.page.fiscalYear')}:`}
                  <Select
                    style={{ width: 150 }}
                    mode="multiple"
                    maxTagCount={1}
                    allowClear
                    options={optData?.accountingYear}
                    value={filtersData.accountingYear}
                    onChange={(v) => handleFilterChange('accountingYear', v)}
                  />
                  {`${t('aipcmcty.page.useCase')}:`}
                  <Select
                    options={optData?.alertOptions}
                    onChange={handleAlertChange}
                    style={{ width: 160 }}
                    value={filtersData.useCase}
                    allowClear
                  />
                </Space>
              </Col>
              <Col style={{ padding: '5px 0' }}>
                <Space>
                  <Popconfirm
                    placement="bottomRight"
                    title={
                      <>
                        {t('aipcmcty.page.recalculateCMC')}
                        <br />
                        {t('aipcmcty.page.updateBudgetCategoryLatestRecommended')}
                      </>
                    }
                    onConfirm={async () => {
                      setTableLoading(true);
                      setKpiLoadingStatus(true);
                      const isUpdateSuccess = await APIList.budgetCategoryUpdate().post({
                        mode: '1',
                        snapshot,
                        snapshotVersion,
                      });
                      if (isUpdateSuccess) {
                        getChartData();
                        await initializeTableData();
                        message.success(t('aipcmcty.page.budgetCategoryUpdatedToLatestRecommendedValues'));
                      }
                      setTableLoading(false);
                    }}
                    onCancel={async () => {
                      setTableLoading(true);
                      setKpiLoadingStatus(true);
                      const isUpdateSuccess = await APIList.budgetCategoryUpdate().post({
                        mode: '0',
                        snapshot,
                        snapshotVersion,
                      });
                      if (isUpdateSuccess) {
                        getChartData();
                        await initializeTableData();
                        message.success(t('aipcmcty.page.recommendedBudgetUpdate'));
                      }
                      setTableLoading(false);
                    }}
                    okText={t('aipcmcty.page.overwrite')}
                    cancelText={t('aipcmcty.page.doNotOverwrite')}
                    disabled={!tableCanOperation}
                  >
                    <Button disabled={!tableCanOperation}>{t('aipcmcty.page.budgetCategoryUpdate')}</Button>
                  </Popconfirm>
                  <Button
                    disabled={!tableCanOperation}
                    onClick={() => {
                      startDsSimulation();
                    }}
                  >
                    {t('aipcmcty.page.demandSupplySimulation')}
                  </Button>
                  <LayoutSwitcher viewMode={viewMode} setViewMode={setViewMode} />
                  <Button
                    onClick={() => {
                      setMenuCollapsed(filterCollapsed);
                      setMemoCollapsed(true);
                      setFilterCollapsed(!filterCollapsed);
                    }}
                  >
                    <BarsOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
            <ChartTableLayout viewMode={viewMode}>
              {kpiMapping ? <ChartTableLayout.Chart>{chartContent()}</ChartTableLayout.Chart> : <></>}
              <ChartTableLayout.Table>
                <ProjectSetupTable
                  data={filteredTableData}
                  columnFilter={colFilter}
                  loading={tableLoading}
                  pageSize={pageSize}
                  onPageSizeChange={handlePageSizeChange}
                  onSortChange={() => setIsUseCaseOne(false)}
                  onExpandChange={handleExpandChange}
                  needDisable={tableCanOperation}
                  expandedRows={expandedRows}
                  viewMode={viewMode}
                />
              </ChartTableLayout.Table>
            </ChartTableLayout>
          </div>
          <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
            {!filterCollapsed && <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />}
          </DrawerContainer>
          <DrawerContainer
            {...drawerOpts}
            collapsed={memoCollapsed}
            closeBtnFunc={() => {
              setMenuCollapsed(false);
              setMemoCollapsed(true);
            }}
          >
            <MemoContainer
              collapsed={memoCollapsed}
              snapshot={snapshot}
              snapshotVersion={snapshotVersion}
              projectId={memoInfo.projectId}
              projectName={memoInfo.projectName}
              searchData={searchData}
            />
          </DrawerContainer>
        </div>
        <Modal
          className="aipcmc"
          title={`${curModalItem?.projectId} ${curModalItem?.projectName} ${t('aipcmcty.page.projectDemandSupplyList')}`}
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            setCurModalItem(null);
          }}
          footer={null}
          width="90%"
          styles={{
            body: {
              height: document.body.clientHeight * 0.8,
            },
          }}
          maskClosable={false}
          centered
        >
          <ProjectSetupDetail project={curModalItem} />
        </Modal>
        <Modal
          className="formation-table"
          title="Formation Table"
          open={isFormationModalOpen}
          okText={t('aipcmcty.page.save')}
          cancelText={t('aipcmcty.page.cancel')}
          onCancel={() => {
            setFormationModalOpen(false);
            setCurTableItem(null);
            setCachedFormation(null);
          }}
          onOk={() => {
            if (!tableCanOperation) {
              setFormationModalOpen(false);
              setCurTableItem(null);
              setCachedFormation(null);
              return;
            }
            const diff = Object.keys(cachedFormation).reduce((acc, cur) => {
              if (cachedFormation[cur] !== curTableItem[cur]) {
                acc.push({
                  projectId: curTableItem.projectId,
                  field: cur,
                  type: 'FORMATION',
                  before: cachedFormation[cur],
                  after: curTableItem[cur],
                  snapshot,
                  snapshotVersion,
                  createdBy: user,
                  projectUuid: curTableItem.id,
                  hasEffimate: curTableItem.demandForecastType?.includes('effimate'),
                });
              }
              return acc;
            }, [] as RecordItem[]);
            setFormationTableLoading(true);
            APIList.demandForecast()
              .put({ ...curTableItem, snapshot, snapshotVersion })
              .then(() => {
                const itemIndex = tableDataNeedFilter.findIndex((t) => t.id === curTableItem.id);
                setTableDataNeedFilter([
                  ...tableDataNeedFilter.slice(0, itemIndex),
                  curTableItem,
                  ...tableDataNeedFilter.slice(itemIndex + 1),
                ]);
                setFormationTableLoading(false);
                setFormationModalOpen(false);
                setCurTableItem(null);
                APIList.postOperationHistory().post({ histories: diff });
                setCachedFormation(null);
                message.success(t('aipcmcty.page.updateFormationProjectId').replace('${curTableItem.projectId}', curTableItem.projectId));
                demandForecastAction(curTableItem);
              })
              .finally(() => {
                setFormationTableLoading(false);
              });
          }}
          width="50%"
          maskClosable={false}
        >
          <div style={{ padding: '0 12px 12px' }}>
            <b>{`${t('aipcmcty.page.projectCaseId')}: `}</b>
            {curTableItem?.projectId}
          </div>
          <div style={{ padding: '0 12px 12px' }}>
            <b>{`${t('aipcmcty.page.projectCaseName')}:`}</b> {curTableItem?.projectName}
          </div>
          <Table
            className="project-setup-form"
            columns={formationTableCols}
            dataSource={formationTableData}
            rowKey="index"
            pagination={false}
            size="small"
            loading={formationTableLoading}
          />
        </Modal>
      </ProjectSetupContext.Provider>
    </>
  );
};

export default ProjectSetup;
