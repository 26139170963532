import * as XLSX from 'xlsx';
import axios from 'axios';
import Excel from 'exceljs';

//将excel的日期格式转成Date()对象;
const getFormatDate = (serial: number) => {
  if (!serial) {
    return null;
  }
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  var d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);

  var add0 = (m: number) => (m < 10 ? `0${m}` : m);
  var YYYY = d.getFullYear();
  var MM = add0(d.getMonth() + 1);
  var DD = add0(d.getDate());
  return `${YYYY}-${MM}-${DD}`;
};

// 读取示例 可根据需求自定义 startLine表示从第几行（从1开始计算）开始是有效数据，通常该行是header的变量名
const readResponse = (res: any, startLine = 3) => {
  const sheets = Object.keys(res);
  const group: any = {};

  sheets.forEach((sheet) => {
    group[sheet] = [];
    const headers = res[sheet][startLine - 1];
    for (let index = startLine; index < res[sheet].length; index++) {
      const element = res[sheet][index];
      if (!element || element.length === 0) {
        continue;
      }
      const item: any = {};
      for (let j = 0; j < headers.length; j++) {
        if (headers[j]) {
          const header = headers[j].trim();
          if (header.match('Date') || header.match('date') || header.match('day')) {
            item[header] = getFormatDate(element[j]);
          } else {
            item[header] = typeof element[j] === 'string' ? element[j].trim() : element[j];
          }
        }
      }
      group[sheet].push(item);
    }
  });
  return group;
};

// 读取excel文件
const readExcelFile = async (filePath: string) => {
  try {
    const response = await axios.get(filePath, { responseType: 'arraybuffer' });

    const data = new Uint8Array(response.data);

    const ret: any = {};

    const wb = XLSX.read(data, { type: 'array' });

    wb.SheetNames.forEach((name: string) => {
      const sheet: any = wb.Sheets[name];
      ret[name] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    });

    return ret;
  } catch (error) {
    console.error('读取文件失败：', error);
  }
};

const readSheetResponse = (sheet: any, startLine = 3) => {
  const resp = [];
  const headers = sheet[startLine - 1];
  for (let index = startLine; index < sheet.length; index++) {
    const element = sheet[index];
    if (!element || element.length === 0) {
      continue;
    }
    const item: any = {};
    for (let j = 0; j < headers.length; j++) {
      if (headers[j]) {
        const header = headers[j].trim();
        if (header.match('Date') || header.match('日付') || header.match('date') || header.match('day')) {
          item[header] = getFormatDate(element[j]);
        } else {
          item[header] = typeof element[j] === 'string' ? element[j].trim() : element[j];
        }
      }
    }
    resp.push(item);
  }
  return resp;
};

const readBufferWithStyles = async (filePath: string) => {
  const response = await axios.get(filePath, { responseType: 'arraybuffer' });
  const buffer = new Uint8Array(response.data);
  const getCellColor = (sheet, cellAddress) => {
    const cell = sheet[cellAddress];
    if (!cell || !cell.s || !cell.s.fgColor) {
      return null;
    }
    return cell.s.fgColor.rgb;
  };

  try {
    const ret: any = {};

    const wb = XLSX.read(buffer, { type: 'array', cellStyles: true });

    wb.SheetNames.forEach((name: string) => {
      const sheet = wb.Sheets[name];

      const json = XLSX.utils.sheet_to_json(sheet, {
        header: 'A',
        blankrows: false,
        raw: false,
      });

      // 遍历所有单元格
      for (const cellAddress in sheet) {
        if (cellAddress[0] === '!') continue; // 跳过特殊的元数据单元格

        // 获取单元格的背景色
        const cellColor = getCellColor(sheet, cellAddress);

        // 获取单元格的行列
        const cellRow = XLSX.utils.decode_cell(cellAddress).r;
        const cellCol = XLSX.utils.decode_cell(cellAddress).c;

        // 合并背景色到 JSON 数据中
        const header = XLSX.utils.encode_col(cellCol);

        if (json[cellRow] === null || json[cellRow] === undefined) continue; // 跳过

        if (cellColor) {
          json[cellRow][header] = {
            value: json[cellRow][header],
            bgColor: cellColor,
          };
        } else {
          json[cellRow][header] = {
            value: json[cellRow][header],
          };
        }
      }
      ret[name] = json;
    });
    return ret;
  } catch (error) {
    console.error('读取文件失败：', error);
  }
};

const readExcelFileWithStyles = async (filePath: string) => {
  try {
    return readBufferWithStyles(filePath);
  } catch (error) {
    console.error('读取文件失败：', error);
  }
};

type ExcelWorkbookData = {
  // sheet name
  sheet: string;
  dataType: 'aoa' | 'json';
  // table data
  data: any[];
  options?: {
    // row index that insertion start. (notice: base with excel template)
    insertRowIndex?: number;
    // row index that declare the mapping keys in excel template. (notice: base with excel template)
    templateMappingKeyRowIndex?: number;
    // merge cell start to cell end (notice: base on the data index)
    merge?: { start: [row: number, column: number]; end: [row: number, column: number] }[];
  };
};

type ExcelCellData = {
  sheet: string;
  data: { [key in any]: any };
};

type ExcelFileType = {
  fileName: string;
  templateFilePath: string;
  sheetData?: ExcelWorkbookData[];
  cellData?: ExcelCellData[];
};

const exportTableFromExcelTemp = async ({ fileName, templateFilePath, sheetData, cellData }: ExcelFileType) => {
  const tempResp = await axios.get(templateFilePath, { responseType: 'arraybuffer' });
  const tempBuffer = new Uint8Array(tempResp.data);
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(tempBuffer as any);
  workbook.eachSheet((sheet) => {
    if (sheetData) {
      const { data, options, dataType } = sheetData.find((d) => d.sheet === sheet.name);
      const rowKeys = sheet.getRow(options?.templateMappingKeyRowIndex ?? 1).values;
      let mappedData: any[] = data;
      if (dataType === 'json') {
        mappedData = data.reduce((acc, sd) => {
          const row = (rowKeys as string[]).map((k) => sd[k]);
          acc.push(row);
          return acc;
        }, []);
      }
      const insertRowIndex = options?.insertRowIndex ?? 2;
      sheet.spliceRows(insertRowIndex, 1, ...mappedData);
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex >= insertRowIndex) {
          row.eachCell((cell) => {
            if (data[rowIndex - insertRowIndex]?.numFmt) {
              cell.numFmt = data[rowIndex - insertRowIndex].numFmt;
            }
          });
        }
      });
      if (options?.merge?.length > 0) {
        options.merge.forEach((m) => {
          const [rowStart, columnStart] = m.start;
          const [rowEnd, columnEnd] = m.end;
          sheet.mergeCells(rowStart + 2, columnStart + 1, rowEnd + 2, columnEnd + 1);
        });
      }
    }
    if (cellData) {
      const { data } = cellData.find((d) => d.sheet === sheet.name);
      Object.keys(data).forEach((cellKey) => {
        const cell = sheet.getCell(cellKey);
        cell.value = data[cellKey];
      });
    }
  });
  const buffer = await workbook.xlsx.writeBuffer();
  // 将工作簿写入一个 Blob 对象
  const blob = new Blob([buffer], {
    type: 'application/octet-stream',
  });
  // 创建一个下载链接
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  // link.target = ""
  link.download = fileName;
  // 将下载链接附加到文档并触发点击事件
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getExcelColumnName = (index: number) => {
  let columnName = '';
  while (index > 0) {
    let remainder = (index - 1) % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = Math.floor((index - 1) / 26);
  }
  return columnName;
};

const getExcelColumnIndex = (columnName: string) => {
  let index = 0;
  for (let i = 0; i < columnName.length; i++) {
    const char = columnName[i].toUpperCase(); // 确保字母是大写
    const charCode = char.charCodeAt(0) - 64; // A 的 ASCII 码是 65，减去 64 得到 1
    index = index * 26 + charCode; // 26 进制计算
  }
  return index;
};

type ExportExcelWithCopyColsConfig = {
  fileName: string;
  templateFilePath: string;
  sheetData: ExportWorkBookDataWithCopyCols[]
};

type ExportWorkBookDataWithCopyCols = {
  // sheet name
  sheet: string;
  dataType: 'aoa' | 'json';
  // table data
  data: any[];
  options?: {
    // row index that insertion start. (notice: base with excel template)
    insertRowIndex?: number;
    // row index that declare the mapping keys in excel template. (notice: base with excel template)
    templateMappingKeyRowIndex?: number;
    // cell styles
    cellStyles?: {
      bold: boolean;
      fgColor: string;
      cells: string[]
    }[];
    // merge cell start to cell end (notice: base on the data index)
    merge?: {
      start: [row: number, column: number];
      end: [row: number, column: number];
    }[];
    copy?: {
      // 目前的 excel 库对于列的复制存在局限性，所以的复制列的解决方法是逐行复制，
      // 因为每一张表可能存在逻辑的特殊性，无法保证共通，所以关于复制的逻辑需要独立传入
      // @params.row: 复制列的遍历行
      // @params.i: 复制列的遍历行索引(从 1 开始)
      // Notice: 因为函数是在使用的地方声明的，
      // 所以可以利用闭包的特性，在传递函数的时候，绑定外部的局部变量参与逻辑
      copyCellLogic: (sheet, row, i) => any;
      copyColStyleLogic: (sheet) => any;
    }
  };
};

const exportCopyColsExcel = async ({
  fileName,
  templateFilePath,
  sheetData
}: ExportExcelWithCopyColsConfig) => {
  const tempResp = await axios.get(templateFilePath, { responseType: 'arraybuffer' });
  const tempBuffer = new Uint8Array(tempResp.data);
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(tempBuffer as any);
  workbook.eachSheet((sheet, index) => {
    if (sheetData && sheetData?.find((d) => d.sheet === sheet.name)) {
      const { data, options, dataType } = sheetData.find((d) => d.sheet === sheet.name);
      if (options?.copy) {
        const { copyCellLogic, copyColStyleLogic } = options.copy;
        sheet.eachRow((row, i) => copyCellLogic(sheet, row, i));
        copyColStyleLogic(sheet);
      }
      const rowKeys = sheet.getRow(options?.templateMappingKeyRowIndex ?? 1).values;
      let mappedData: any[] = data;
      if (dataType === 'json') {
        mappedData = data.reduce((acc, sd) => {
          const row = (rowKeys as string[]).map((k) => sd[k]);
          acc.push(row);
          return acc;
        }, []);
      }
      const insertRowIndex = options?.insertRowIndex ?? 2;
      sheet.spliceRows(insertRowIndex, 1, ...mappedData);
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex >= insertRowIndex) {
          row.eachCell((cell) => {
            if (data[rowIndex - insertRowIndex]?.numFmt) {
              cell.numFmt = data[rowIndex - insertRowIndex].numFmt;
            }
          });
        }
      });
      if (options?.merge?.length > 0) {
        options.merge.forEach((m) => {
          const [rowStart, columnStart] = m.start;
          const [rowEnd, columnEnd] = m.end;
          sheet.mergeCells(rowStart + 2, columnStart + 1, rowEnd + 2, columnEnd + 1);
        });
      }

      if (options?.cellStyles?.length) {
        options.cellStyles.forEach(({ bold, fgColor, cells }) => {
          cells.forEach(cName => {
            const cell = sheet.getCell(cName);
            if (fgColor) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor:{ argb: fgColor }
              };
            }
            if (bold) {
              cell.font = {
                ...cell.font,
                bold
              };
            }
          })
        });
      }
    }
  });
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/octet-stream',
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // 将下载链接附加到文档并触发点击事件
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export {
  readExcelFile,
  getFormatDate,
  readResponse,
  readSheetResponse,
  readExcelFileWithStyles,
  readBufferWithStyles,
  getExcelColumnName,
  getExcelColumnIndex,
  exportTableFromExcelTemp,
  exportCopyColsExcel
};
