import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Select, Space, Switch, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useVersion from '../../hooks/useVersion';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import APIList from '../../http/ApiList';
import {
  chain,
  eq,
  forEach,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  pick,
  debounce as _debounce,
  filter,
  set,
  round,
  values,
  flatten,
  find,
  keys,
  toNumber,
  isArray,
  orderBy,
} from 'lodash';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { useWindowSize } from '../../hooks/useWindowSize';
import { departmentOpts as dOpts } from '../simulation/resource-simulation';
import { AppContext } from "../../contexts/AppContext";
import { projectCompareExport } from './p-diff';

const departmentOpts = dOpts.map((d) => {
  if (d.value !== 'その他: 過去分') {
    return { ...d, isSelected: true };
  }
  return { ...d };
});

/**
 * create FY number
 * * FY25, FY26....
 * @param beforeDay before now year number
 * @param afterDay  after now year number
 */
const createFyNum = (beforeDay: number, afterDay: number) => {
  const start = toNumber(dayjs().add(-beforeDay, 'year').format('YY'));
  const end = toNumber(dayjs().add(afterDay, 'year').format('YY'));
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

const kpiOpts = [
  { label: '受注金額',
    value: '受注金額',
    key: '受注金額',
    dataIndex: 'orderAmount',
    isSelected: true,
    width: 80
  },
  { label: '受注金額(明細)',
    value: '受注金額(明細)',
    key: '受注金額(明細)',
    dataIndex: 'orderAmountStandalone',
    isSelected: true,
    width: 120
  },
  {
    label: '受注粗利',
    value: '受注粗利',
    key: '受注粗利',
    dataIndex: 'orderGrossProfit',
    isSelected: true,
    width: 80,
  },
  {
    label: '粗利率',
    value: '粗利率',
    key: '粗利率',
    width: 70,
  },
  ...map(createFyNum(1, 3), (v) => ({
    label: `FY${v}粗利`,
    value: `年度跳ね返り粗利FY${v}`,
    key: `年度跳ね返り粗利FY${v}`,
    dataIndex: `grossProfitFy${v}`,
    isSelected: `${dayjs().format('YY')}` == `${v}`,
    width: 80,
  })),
  {
    label: '需要MH',
    value: '需要MH',
    key: '需要MH',
    dataIndex: 'demandMh',
    width: 90,
  },
];

const modeTarget = map(kpiOpts, 'dataIndex').filter((i) => i);
const grossProfitArray = chain(kpiOpts)
  .filter((v) => includes(v.value, '年度跳ね返り粗利'))
  .map('value')
  .value();

const categoryCustomOpts = [
  { label: 'Awarded', value: 'Awarded', key: 'Awarded' },
  { label: 'Budget', value: 'Budget', key: 'Budget', isSelected: true },
  { label: 'IF', value: 'IF', key: 'IF' },
  { label: 'Others', value: 'Others', key: 'Others' },
];

/**
 * handle table column by snapshot
 * @param snapshot type of key: { 'snapshot', 'snapshotVersion' }
 */
const handleCellBySnapshot = (
  snapshot: any[],
  kpisSelected: any[],
  versionMode: any,
  defaultCols,
) => {
  const columns = [...defaultCols];
  // const leftSelectedSnapshot = find(snapshot, ['target', true]);
  const sortSnapshot = chain(snapshot)
    .filter((i) => !i.target)
    .orderBy(['snapshot'], ['asc'])
    // .push(leftSelectedSnapshot)
    .value();
  forEach(sortSnapshot, (i) => {
    const snapshotVersionName = find(
      get(versionMode, i.snapshot),
      ['value', i.snapshotVersion]
    )?.label;
    columns.push(
      {
        title: (
          <>
            <div style={{ textAlign: 'center' }}>{i.snapshot}</div>
            <div style={{ textAlign: 'center' }}>[{snapshotVersionName}]</div>
          </>
        ),
        className: 'border-left',
        children: map(kpiOpts, (kpi) => {
          // only one
          const key = kpi.value;
          const target = `${i.snapshot} ${i.snapshotVersion}`;
          const hidden = isEmpty(kpisSelected) ? false : !includes(kpisSelected, key);
          const configure = {
            title: <><div style={ {textAlign: 'center'} }>{ kpi.label }</div></>,
            key: kpi.key, hidden, width: kpi.width,
          };
          if (kpisSelected[0] === kpi.value) {
            set(configure, 'className', 'border-left');
          }
          if (includes(['受注金額', '受注金額(明細)', '受注粗利', ...grossProfitArray], key)) {
            set(configure, 'dataIndex', [kpi.dataIndex, `${i.snapshot} ${i.snapshotVersion}`]);
            set(configure, 'render', (value: any) => {
              if (isNil(value)) return <><div style={ {textAlign: 'right'} }>-</div></>;
              const show = round(value / 1000000, 0);
              return <><div style={ {textAlign: 'right'} }>{ show.toLocaleString() }</div></>;
            });
          } else if (eq('粗利率', key)) {
            set(configure, 'render', (_, data) => {
              if (isNil(data.orderAmount?.[target])) return <><div style={ {textAlign: 'right'} }>-</div></>;
              const show = ((data.orderGrossProfit?.[target] ?? 0) / data.orderAmount[target] * 100).toFixed(1);
              return <><div style={ {textAlign: 'right'} }>{ data.orderAmount[target] == 0 ? '-': `${show}%` }</div></>;
            });
          } else if (eq('需要MH', key)) {
            set(configure, 'render', (_, data) => {
              if (isNil(data.demandMh?.[target])) return <><div style={ {textAlign: 'right'} }>-</div></>;
              const show = data.demandMh?.[target];
              return <><div style={ {textAlign: 'right'} }>{ show.toLocaleString() }</div></>;
            });
          }
          return configure;
        })
    });
  });
  return columns;
};

/**
 * set table columns hidden by selected kip options
 * @param kipsSelected selected kip options
 */
const hiddenForCellKips = (tableColumns: any, kpisSelected: string[]) => {
  return map(tableColumns, (item, index) => {
    if (!index) return item;
    if (index) {
      forEach(item.children, (row) => {
        row.hidden = !includes(kpisSelected, row.key);
      });
    }
    return item;
  });
};

const sortDirections = [null, 'asc', 'desc'];

const SubDiffPage: React.FC = () => {
  /** about common config */
  const { t } = useTranslation();
  const { snapshot, snapshotVersion, allSnapshotList, allVersionOptCollection } = useVersion(false);
  const { certainty, demandMhPercent } = useContext(AppContext);
  const { selectorHeight4Table } = useWindowSize({
    selector: '.sub-diff-table .cmcty-table-body',
    viewMode: 'table-only',
    watcherSelector: ['.operation-container'],
  });
  /** 变量定义 */
  // 検索条件
  const [sifter, setSifter] = useState({
    fiscalYear: [`FY${dayjs().format('YY')}`],
    kpis: [...chain(kpiOpts).filter('isSelected').map('value').value()],
    departments: [...chain(departmentOpts).filter('isSelected').map('value').value()],
    allMode: false,
    tgcs: [],
    category: [...chain(categoryCustomOpts).filter('isSelected').map('value').value()],
  });
  // 年度
  const [fiscalYearOpts, setFiscalYearOpts] = useState([]);
  // tgc options
  const [tgcOpts, setTgcOpts] = useState([]);
  // 比較バージョンを追加
  const [form] = Form.useForm();
  // 「比較バージョンを追加」の Modal
  const [modalOpen, setModalOpen] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);
  // table show data
  const [tableData, setTableData] = useState([]);
  // table data cache
  const [cacheTableSource, setCacheTableSource] = useState([]);
  // table columns
  const [tableColumn, setTableColumn] = useState([]);
  // table width
  const [tableWidth, setTableWidth] = useState(1000);

  // specified sort columns
  const sortReducer = (state, action) => {
    const { sortKey, sortDir } = state;
    switch (action.type) {
      case 'subProjectId':
        if (sortKey !== 'subProjectId') {
          return { sortKey: 'subProjectId', sortDir: 1 };
        }
        return { sortKey: 'subProjectId', sortDir: sortDir + 1 > 2 ? 0 : sortDir + 1 };
      case 'orderScheduledDate':
        if (sortKey !== 'orderScheduledDate') {
          return { sortKey: 'orderScheduledDate', sortDir: 1 };
        }
        return { sortKey: 'orderScheduledDate', sortDir: sortDir + 1 > 2 ? 0 : sortDir + 1 };
      default:
        return state;
    }
  };
  const [sortState, sortDispatch] = useReducer(sortReducer, {
    sortKey: null,
    sortDir: 0
  });
  useEffect(() => {
    handleFilterChange(sifter);
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, sifter.kpis, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, sifter.kpis);
    setTableColumn(columns);
  }, [sortState]);

  const defTableColumns: ColumnsType = [
    {
      className: 'border-right-none',
      children: [
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>本部</div>
            </>
          ),
          dataIndex: 'department',
          key: 'department',
          width: 70,
          onCell: (item) => {
            const rowSpan = get(item, 'DEPARTMENT_ROWSPAN', 0);
            return { rowSpan };
          },
        },
        {
          title: <div onClick={() => sortDispatch({ type: 'subProjectId' })} style={{ textAlign: 'center' }}>案件ID</div>,
          dataIndex: 'subProjectId',
          key: 'subProjectId',
          width: 78,
          sorter: () => 0,
          sortOrder: sortState.sortKey === 'subProjectId' ? [null, 'ascend', 'descend'][sortState.sortDir] as any : null,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 7 };
            }
            return { colSpan: 1 };
          },
          render: (value, item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return (
                <>
                  <div style={{ textAlign: 'right' }}> {value} </div>
                </>
              );
            }
            return value;
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>案件名</div>
            </>
          ),
          dataIndex: 'subProjectName',
          key: 'subProjectName',
          width: 260,
          ellipsis: true,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>TGC</div>
            </>
          ),
          dataIndex: 'tgc',
          key: 'tgc',
          width: 80,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>役務範囲</div>
            </>
          ),
          dataIndex: 'scope',
          key: 'scope',
          width: 180,
          ellipsis: true,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>会計年度</div>
            </>
          ),
          dataIndex: 'fiscalYear',
          key: 'fiscalYear',
          width: 75,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: <div onClick={() => sortDispatch({ type: 'orderScheduledDate' })} style={{textAlign: 'center'}}>受注予定日</div>,
          dataIndex: 'orderScheduledDate',
          width: 110,
          sorter: () => 0,
          sortOrder: sortState.sortKey === 'orderScheduledDate' ? [null, 'ascend', 'descend'][sortState.sortDir] as any : null,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          }
        },
        {
          title: <><div style={ {textAlign: 'center'} }>予算カテゴリ</div></>,
          dataIndex: 'categoryCustom',
          className: 'border-right-none',
          // key: 'categoryCustom',
          width: 110,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type === 'DATA_TOTAL') {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          }
        },
      ],
    },
  ];

  const saveListToLocalStorage = (list) => {
    if (!isEmpty(list)) {
      localStorage.setItem('snapshot-sub-diff', JSON.stringify(list));
    }
  };
  /** init data */
  // init of snapshot default value
  const defSnapshot = useMemo(() => {
    const formValue = {
      list: [
        { snapshot: '2024-02-28', snapshotVersion: 'default' },
        { snapshot: '2024-08-14', snapshotVersion: 'default' },
        { snapshot, snapshotVersion },
      ],
    };
    // TODO:
    // Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop?
    // 问题出现在这里
    let localSnapshotList = localStorage.getItem('snapshot-sub-diff');
    if (!localSnapshotList && !JSON.parse(localSnapshotList)) {
      form.setFieldsValue(formValue);
      return formValue;
    } else {
      form.setFieldsValue({
        list: JSON.parse(localSnapshotList),
      });
      return {
        list: JSON.parse(localSnapshotList),
      };
    }
    // }, [orgAllSnapshots]);
  }, []);
  // init of fiscal year options
  useEffect(() => {
    APIList.getCmcOptions()
      .get({
        category: 'accountingYear',
        snapshot,
        snapshotVersion,
      })
      .then((res) => {
        setFiscalYearOpts(
          map(res, (i) => ({
            ...pick(i, ['value', 'label']),
            key: i.value,
          }))
        );
      })
      .catch(() => {
        message.error('init account year data error');
      });
    APIList.getCmcOptions()
      .get({
        category: 'tgc',
        snapshot,
        snapshotVersion,
      })
      .then((res) => {
        const opts = filter(res, (item) => item.attribute1 !== 'equity');
        setTgcOpts(opts);
        sifter.tgcs = map(opts, 'value');
      })
      .catch(() => {
        message.error('init tgc data error');
      });
    // }, [snapshot, snapshotVersion]);
  }, []);

  /** public function */
  // get selected snapshot version list
  const getSnapshotVersion = () => {
    return (
      chain(
        // 当不打开弹窗时，form值不会被定义，为空，此时从数据中计算version信息
        get(isEmpty(form.getFieldsValue()) ? defSnapshot : form.getFieldsValue(), 'list', [])
      )
        .filter((i) => !isNil(i))
        .map((i) => pick(i, ['snapshot', 'snapshotVersion', 'snapshotVersionName']))
        // .push({ snapshot, snapshotVersion, target: true })
        .uniqBy((i) => `${i.snapshot} ${i.snapshotVersion}`)
        .value()
    );
  };

  /** selector change function */
  // kpi selector change fun.
  const kpiSelectorChange = (selected: string[]) => {
    const kpiOrder = kpiOpts.map((opt, i) => opt.value);
    let orderSelected = kpiOrder.reduce((prev, kpi) => {
      if (selected.includes(kpi)) {
        prev.push(kpi)
      }
      return prev;
    }, []);
    // update kip selector values.
    setSifter(value => ({ ...value, 'kpis': orderSelected }));
    // set table column by kpi select
    if (isEmpty(orderSelected)) {
      orderSelected = map(kpiOpts, 'value');
    }
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, orderSelected, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, orderSelected);
    setTableColumn(columns);
  };

  const splitVersionVals = (verVals: any, key: string, splitItem: any) => {
      Object.keys(verVals).forEach(k => {
        const [s, sv] = k.split(' ');
        splitItem[`${key}_${s}_${sv}`] = verVals[k];
        if (key === 'orderGrossProfit') {
          splitItem[`grossMargin_${s}_${sv}`] = !isNil(splitItem[`orderGrossProfit_${s}_${sv}`]) && !!splitItem[`orderAmount_${s}_${sv}`]
            ? splitItem[`orderGrossProfit_${s}_${sv}`] / splitItem[`orderAmount_${s}_${sv}`]
            : null;
        }
      });
    };

  const genExportTableData = () => {
    const exportTables = [];
    tableData.forEach(t => {
      const {
        department,
        subProjectId,
        subProjectName,
        tgc,
        scope,
        fiscalYear,
        categoryCustom,
        orderAmount,
        orderAmountStandalone,
        orderGrossProfit,
        orderScheduledDate,
        demandMh,
        grossProfitFy24,
        grossProfitFy25,
        grossProfitFy26,
        grossProfitFy27,
        grossProfitFy28
      } = t;
      const eItem = {
        department: department,
        projectId: subProjectId,
        projectName: subProjectName,
        tgc: tgc,
        scope: scope,
        fiscalYear: fiscalYear,
        budgetCategoryCustom: categoryCustom,
        orderScheduledDate
      };
      splitVersionVals(orderAmount, 'orderAmount', eItem);
      splitVersionVals(orderAmountStandalone, 'orderAmountStandalone', eItem);
      splitVersionVals(orderGrossProfit, 'orderGrossProfit', eItem);
      splitVersionVals(demandMh, 'demandMh', eItem);
      splitVersionVals(grossProfitFy24, 'gp24', eItem);
      splitVersionVals(grossProfitFy25, 'gp25', eItem);
      splitVersionVals(grossProfitFy26, 'gp26', eItem);
      splitVersionVals(grossProfitFy27, 'gp27', eItem);
      splitVersionVals(grossProfitFy28, 'gp28', eItem);
      exportTables.push(eItem);
    });
    return exportTables;
  };

  const filterDataSetup = (snapshots: string[]) => {
    const filterList: any[] = [];
    const certaintyOpt = [
      {
        value: 0,
        label: t('aipcmcty.page.wonPercentageNotConsidered'),
      },
      {
        value: 1,
        label: t('aipcmcty.page.wonPercentageConsidered'),
      },
    ];
    const getValue = (value, SP = ', ') => {
      const EMPTY = '-';
      if (isArray(value)) {
        if (
          value.length === 0
          || value.every((item) => isNil(item) || item === '')
        ) {
          return EMPTY;
        }
        return value.join(SP);
      }
      if (isNil(value) || value === '') {
        return EMPTY;
      }
      return value;
    };
    // Compare snapshot versions
    snapshots.forEach((s, i) => {
      filterList.push([i === 0 ? '比較バージョン' : '', 'Version', s]);
    });
    // 算出設定
    filterList.push([
      t('aipcmcty.page.calculationSettings'),
      t('aipcmcty.page.budgetRevenueCalculation'),
      getValue(certaintyOpt.find((item) => item.value === certainty)?.label),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.supplyDemandGapAllowed'),
      getValue(demandMhPercent.toLocaleString()),
    ]);
    // Header Filter
    filterList.push(['Header Filter', '本部', getValue(sifter.departments)]);
    filterList.push(['', 'TGC', getValue(sifter.tgcs)]);
    filterList.push(['', '会計年度', getValue(sifter.fiscalYear)]);
    filterList.push(['', '予算カテゴリ', getValue(sifter.category)]);
    filterList.push(['', 'モード', sifter.allMode ? '全量' : '差分']);
    return filterList;
  };

  /** export button click function */
  const handleExportClick = async () => {
    setLoading(true);
    // Step1: Generate base data in table
    const exportTables = genExportTableData();
    // Step2: Set Export Excel Table Data and Configurations
    const snapshots = getSnapshotVersion().map(s => {
      const ver = allVersionOptCollection[s.snapshot].find(l => l.value === s.snapshotVersion);
      return { ...s, snapshotVersionName: ver.label };
    });
    await projectCompareExport(
      9,
      18,
      '/assets/sub_projects_compare_template.xlsx',
      snapshots,
      exportTables,
      filterDataSetup,
      'sub_projects_compare'
    );
    setLoading(false);
  };

  /** compare button click function */
  const handleCompareClick = () => {
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, sifter.kpis, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, sifter.kpis);
    setTableColumn(columns);
    let width = 0;
    forEach(columnInfo, (item: any) => {
      forEach(item.children, (i) => {
        width += i.hidden ? 0 : i.width;
      });
    });
    setTableWidth(width);
    setModalOpen(false);
    findTableDataAjax();
  };

  /** about filters */
  // selector change func
  const filterSelectorChangeCallBack = (fKey: string, changedValues: string[] | boolean) => {
    const newSifter = { ...sifter, [fKey]: changedValues };
    setSifter(newSifter);
    handleFilterChange(newSifter);
  };
  // handle data by filter
  const handleFilterChange = (newSifter: any) => {
    const selectedSnapshotVersionNum = getSnapshotVersion().length;
    const defTgcFilterList = map(tgcOpts, 'value');
    // clear rowSpan setting
    chain(tableData)
      .groupBy('department')
      .forEach((v) => {
        set(v, [0, 'DEPARTMENT_ROWSPAN'], undefined);
      })
      .value();
    const source = chain(cacheTableSource)
      .filter((i) => {
        // 年度
        const fiscalYearFilter = isEmpty(newSifter.fiscalYear) ? true : includes(newSifter.fiscalYear, i.fiscalYear);
        // 本部
        const departmentFilter = isEmpty(newSifter.departments) ? true : includes(newSifter.departments, i.department);
        // TGC
        // !includes(['OFS', 'TPS'], i.tgc)
        // const tgcFilter = isEmpty(newSifter.tgcs) ? true : includes(newSifter.tgcs, i.tgc);
        const tgcFilter = includes(isEmpty(newSifter.tgcs) ? defTgcFilterList : newSifter.tgcs, i.tgc);
        // モード
        // let modeFilter = true;
        let modeFilter = newSifter.allMode;
        if (!newSifter.allMode) {
          for (const key of modeTarget) {
            // 从数据中获取到，每一个和kpi相关值对应的Map
            const target = get(i, key, null);
            // 判断Map的`键`是否与snapshot的list数一致
            const keyNumIsSame = keys(target).length === selectedSnapshotVersionNum;
            if (!keyNumIsSame) {
              modeFilter = true;
              break;
            }
            // // 判断Map的`值`是否与snapshot的list数一致
            // const valueNumIsSame = values(target).length === selectedSnapshotVersionNum;
            // if (!valueNumIsSame) {
            //   modeFilter = true;
            //   break;
            // }
            // 对比值是否一致
            // const valueIsSame = uniq(values(target)).length !== 1;
            const hasDiff =
              chain(target)
                .values()
                .map((i) => {
                  return i ? Number((i / 1000000).toFixed(1)) : i;
                })
                .uniq()
                .value().length !== 1;
            if (hasDiff) {
              modeFilter = true;
              break;
            }
          }
        }
        // 予算カテゴリ
        const categoryFilter = isEmpty(newSifter.category) ? true : includes(newSifter.category, i.categoryCustom);
        // TODO: modeFilter
        return fiscalYearFilter && departmentFilter && tgcFilter && categoryFilter && modeFilter;
      })
      .groupBy('department')
      .mapValues(items => {
        const { sortDir, sortKey } = sortState;
        if (sortDir > 0) {
          return orderBy(items, [sortKey], [sortDirections[sortDir] as any]);
        }
        return items;
      })
      .value();
    // 每组值中加入合计
    const selectedSnapshotVersionKeyList = map(getSnapshotVersion(), (i) => `${i.snapshot} ${i.snapshotVersion}`);
    const appendCountData = chain(source)
      .map((data, department) => {
        // 处理小计
        const countRow = { department, subProjectId: `小計 ${department}`, type: 'DATA_TOTAL' };
        forEach(data, (row) => {
          forEach(selectedSnapshotVersionKeyList, (key) => {
            forEach([...modeTarget], (t) => {
              const oldNum = get(countRow, [t, key]) ?? 0;
              const inputNum = get(row, [t, key]) ?? 0;
              set(countRow, [t, key], oldNum + inputNum);
            });
          });
        });
        data.push(countRow);
        // 原始数据以`本部`为单位的数据结束位置，用于合并列;
        set(data, [0, 'DEPARTMENT_ROWSPAN'], data.length);
        return data;
      })
      .value();
    const filterData = flatten(values(appendCountData));
    setTitleDataSize((filterData.length ?? 0) - appendCountData.length);
    setTableData(filterData);
  };

  /** change table title for data size number */
  const setTitleDataSize = (num: number) => {
    if (isEmpty(tableColumn)) return;
    const columns = [...tableColumn];
    set(
      columns,
      [0, 'title'],
      <>
        <div>　</div>
        <div style={{ width: '100%' }}>
          <span style={{ float: 'left' }}>件数：{num}件</span>
          <span style={{ float: 'right' }}>単位：百万円</span>
        </div>
      </>
    );
    setTableColumn(columns);
  }

  /** about snapshot version modal */
  const handleFormFieldChange = (changed) => {
    const isEq = chain(changed).get([0, 'name', 2]).eq('snapshot').value();
    if (!isEq) return;
    const index = chain(changed).get([0, 'name', 1]).value();
    form.setFieldValue(['list', index, 'snapshotVersion'], 'default');
  };

  /** about table data */
  useEffect(() => {
    if (!tgcOpts.length) {
      handleCompareClick();
    }
  }, [tgcOpts]);
  // after table data load, execute filter function.
  useEffect(() => {
    handleFilterChange(sifter);
  }, [cacheTableSource]);
  const findTableDataAjax = () => {
    setLoading(true);
    const snapshots = getSnapshotVersion().map((i) => ({
      snapshot: i.snapshot,
      snapshotVersion: i.snapshotVersion,
    }));
    const body = { snapshots };
    APIList.getSubDiffCompare()
      .post(body)
      .then((data) => {
        setCacheTableSource(data);
      })
      .catch(() => {
        message.error('sub diff Data Error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableRowClass = (record: any) => {
    const countRow = get(record, 'type');
    if (countRow === 'DATA_TOTAL') {
      return 'count-row border-buttom-line';
    }
    const startDepartment = get(record, 'DEPARTMENT_ROWSPAN');
    if (startDepartment) {
      return 'border-buttom-line';
    }
    return '';
  };

  return (
    <div className="sub-diff-container">
      <Row className="operation-container" justify="space-between">
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.trinityComparison.department')}:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 180 }}
              options={departmentOpts}
              value={sifter.departments}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('departments', e)}
            />
            TGC:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 140 }}
              options={tgcOpts}
              value={sifter.tgcs}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('tgcs', e)}
            />
            会計年度:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 140 }}
              options={fiscalYearOpts}
              value={sifter.fiscalYear}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('fiscalYear', e)}
            />
            予算カテゴリ:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 160 }}
              options={categoryCustomOpts}
              value={sifter.category}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('category', e)}
            />
            {t('aipcmcty.page.trinityComparison.kpi')}:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 160 }}
              options={kpiOpts}
              value={sifter.kpis}
              maxTagCount="responsive"
              onChange={kpiSelectorChange}
            />
            {t('aipcmcty.page.trinityComparison.mode')}:
            <Switch
              disabled={loading}
              checkedChildren="全量"
              unCheckedChildren="差分"
              checked={sifter.allMode}
              onChange={(e) => filterSelectorChangeCallBack('allMode', e)}
            />
            <Button type="dashed" block disabled={loading} icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
              {t('aipcmcty.page.trinityComparison.compareVersionAdd')}
            </Button>
          </Space>
        </Col>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            <Button type="primary" onClick={handleCompareClick} disabled={loading}>
              {t('aipcmcty.page.trinityComparison.compare')}
            </Button>
            <Button type="primary" onClick={handleExportClick} disabled={loading}>
              Export
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        rowKey="id"
        className="sub-diff-table"
        size="small"
        scroll={{ x: tableWidth, y: selectorHeight4Table }}
        dataSource={tableData}
        columns={tableColumn}
        loading={loading}
        pagination={false}
        rowClassName={handleTableRowClass}
      />
      <Modal
        width={650}
        title={t('aipcmcty.page.trinityComparison.compareVersionAdd')}
        open={modalOpen}
        footer={null}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form name="snapshotVersions" form={form} onFieldsChange={handleFormFieldChange}>
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {map(fields, ({ key, name, ...restField }) => {
                  return (
                    <Space key={key} style={{ display: 'flex' }} align="baseline">
                      <Form.Item
                        {...restField}
                        label={t('aipcmcty.page.trinityComparison.compareSnapshot')}
                        name={[name, 'snapshot']}
                        style={{ marginBottom: 12 }}
                      >
                        <Select style={{ width: 150 }} options={allSnapshotList} />
                      </Form.Item>
                      <Form.Item shouldUpdate style={{ marginBottom: 12 }}>
                        {() => (
                          <Form.Item
                            {...restField}
                            label={t('aipcmcty.page.trinityComparison.compareVersion')}
                            name={[name, 'snapshotVersion']}
                            style={{ marginBottom: 12 }}
                          >
                            <Select
                              style={{ width: 150 }}
                              options={get(allVersionOptCollection, get(form.getFieldValue('list'), [name, 'snapshot']))}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                      {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                    </Space>
                  );
                })}
                {fields?.length < 4 && (
                  <Form.Item>
                    <Button type="dashed" style={{ width: 510 }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item style={{ marginTop: 30 }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                handleCompareClick();
                const snapshots = getSnapshotVersion();
                saveListToLocalStorage(snapshots);
              }}
            >
              {t('aipcmcty.page.trinityComparison.compare')}
            </Button>
            <Button
              htmlType="button"
              style={{ margin: '0 8px' }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t('aipcmcty.page.cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SubDiffPage;
